import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'


export default function useItemList(patient_id) {


  const toast = useToast()

  const medicine_Data = ref({});
  const medicine_category_Data = ref({});
  const medicine_item_Data = ref({});

  const meterial_Data = ref({});
  const meterial_medicine_Data = ref({});

  
  const course_Data = ref({});
  const course_item_Data = ref({});
  const course_medicine_Data = ref({});

    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    
  
    
    const fetchMedicineData = async () => {

        medicine_Data.value  = [];
      await store
          .dispatch('app-item/fetchMedicineData', {
            is_active:1,
            //branch_id:branch_id,
            order_by:'id DESC'
         
    }).then(response=>{
        medicine_Data.value = response.data.data;

      }).catch((error)=>{
        console.log('error',error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Fetch medicine",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchMedicineCategoryData = async () => {

    medicine_category_Data.value  = [];
    await store
        .dispatch('app-item/fetchMedicineCategoryData', {
          is_active:1,
       //   branch_id:branch_id,
          order_by:'id DESC'
      
  }).then(response=>{
    medicine_category_Data.value = response.data.data;

    }).catch((error)=>{
      console.log('error',error);
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Fetch medicine",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  fetchMedicineCategoryData();

  const fetchMeterialData = async () => {

    meterial_Data.value  = [];
    await store
        .dispatch('app-item/fetchMeterialData', {
          is_active:1,
          //branch_id:branch_id,
          order_by:'id DESC'
      
  }).then(response=>{
    meterial_Data.value = response.data.data;
    
    }).catch((error)=>{
      console.log('error',error);
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Fetch medicine",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }


  const fetchMeterialMedicineData = async () => {

    meterial_medicine_Data.value  = [];
    await store
        .dispatch('app-item/fetchMeterialMedicineData', {
          is_active:1,
          //branch_id:branch_id,
          order_by:'meterial_medicine.id DESC'
      
  }).then(response=>{
    return meterial_medicine_Data.value = response.data.data;
    
    }).catch((error)=>{
      console.log('error',error);
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Fetch medicine",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }




  fetchMeterialMedicineData();

    const fetchMedicineItemData = async () => {

      medicine_item_Data.value  = [];
      await store
          .dispatch('app-item/fetchMedicineItemData', {
            is_active:1,
           // branch_id:branch_id,
            order_by:'medicine_item.id DESC'
         
    }).then(response=>{
      medicine_item_Data.value = response.data.data;

      }).catch((error)=>{
        console.log('error',error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Fetch medicine",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchCourseItemData = async () => {

    course_item_Data.value  = [];
    await store
      .dispatch('app-item/fetchCourseItemData', {
        is_active:1,
      //  branch_id:branch_id,
        patient_id:patient_id.value,
        balance:'0',
        order_by:'id DESC'
     
    }).then(response=>{
      course_item_Data.value = response.data.data;

      }).catch((error)=>{
        console.log('error',error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Fetch medicine",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
  
    const fetchCourseMedicineData = async () => {

      course_medicine_Data.value  = [];
      await store
        .dispatch('app-item/fetchCourseMedicineData', {
          is_active:1,
         // branch_id:branch_id,
          order_by:'id DESC'
       
      }).then(response=>{
        course_medicine_Data.value = response.data.data;
  
        }).catch((error)=>{
          console.log('error',error);
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Fetch medicine",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }
  

  fetchMedicineData();
  fetchCourseMedicineData();
  fetchMeterialData();
  fetchCourseItemData();
  fetchMedicineItemData();
  
  const fetchMedicineDeleteItemData = async (id) => {
    //fetchDeleteProperty
    var data = {id:id}
    
     await store.dispatch('app-item/fetchMedicineDeleteItemData',data)
    .then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchOpdItemData();
    }).catch((error)=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
  }
  

  const resolveMedicineVariant = status =>{
    if (status == 'ยา') return 'danger'
    else if (status == 'อุปกรณ์') return 'primary'
    return 'light'
  }
  const resolveMedicineGroupnameVariant = status =>{
    if (status == 'ยา') return 'HeartIcon'
    else if (status == 'อุปกรณ์') return 'SquareIcon'
    return 'light'
  }

  const resolveMeterialVariant = status =>{
    if (status == 3 || status == 'วัสดุ') return 'dark'
    else if (status == 4 || status == 'บริการ') return 'warning'
    return 'light'
  }
  const resolveMeterialGroupnameVariant = status =>{
    if (status == 3 || status == 'วัสดุ') return 'ShieldIcon'
    else if (status == 4 || status == 'บริการ') return 'SmileIcon'
    return 'light'
  }

    return {
        fetchMedicineData,
        fetchCourseItemData,
        fetchCourseMedicineData,
        fetchMeterialData,
        fetchMeterialMedicineData,
        fetchMedicineItemData,

        fetchMedicineDeleteItemData,
        medicine_item_Data,
        medicine_Data,
        meterial_Data,
        medicine_category_Data,
        meterial_medicine_Data,
        course_Data,
        course_item_Data,
        course_medicine_Data,
        resolveMedicineVariant,
        resolveMedicineGroupnameVariant,
        resolveMeterialVariant,
        resolveMeterialGroupnameVariant,
    }
}