import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchMedicineData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/medicines/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchMedicineCategoryData(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post('/medicines/category/v1/getall',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        fetchMedicineItemData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/medicines/item/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        addMedicineItemData(ctx,queryParams){
          var data = queryParams;
            
          return new Promise((resolve, reject) => {
            axios
              .post('/medicines/item/v1/add',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        updateMedicineItemData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .put(`/medicines/item/v1/update/${queryParams.opdcard_item_id[0]}`,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        


        fetchMeterialData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/meterial/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchMeterialMedicineData(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post('/meterial/medicine/v1/getall',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        fetchMeterialItemData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/meterial/item/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        addMeterialItemData(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post('/meterial/item/v1/add',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        updateMeterialItemData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post(`/meterial/item/v1/update/${data.id}`,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        



        fetchCourseData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/course/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchCourseMedicineData(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post('/course/medicines/v1/getall',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        fetchCourseItemData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/courses/item/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        addCourseItemData(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post('/courses/item/v1/add',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        updateCourseItemData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .put(`/courses/item/v1/update/0`,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        

        //opdcard_item
        addOpdcardItemData(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post('/opdcard/item/v1/add',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
    }
}