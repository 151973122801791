import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/opd/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        async addData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/opd/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put(`/opd/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .delete(`/opd/v1/delete/${data.id}`)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        fetchOpdCardData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/opdcard/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        async addOpdCardData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/opdcard/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        updateOpdCardFaceImageData(ctx,queryParams){
         
          // var data = JSON.stringify(queryParams.formData);
           
           return new Promise((resolve, reject) => {
             axios
               .put('/opdcard/v1/faceimage/update/'+queryParams.data.id,queryParams.formData,{
                 headers:{
                   'Content-Type': 'multipart/form-data',
                   'dataType':'json'
                 }
               })
               .then(response => resolve(response))
               .catch(error => reject(error))
           })

       },
        updateOpdCardSignImageData(ctx,queryParams){
         
            // var data = JSON.stringify(queryParams.formData);
             
             return new Promise((resolve, reject) => {
               axios
                 .put('/opdcard/v1/signimage/update/'+queryParams.data.id,queryParams.formData,{
                   headers:{
                     'Content-Type': 'multipart/form-data',
                     'dataType':'json'
                   }
                 })
                 .then(response => resolve(response))
                 .catch(error => reject(error))
             })
 
         },
        async updateOpdCardData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put(`/opdcard/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteOpdCardData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .delete(`/opdcard/v1/delete/${data.id}`)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        fetchPatientData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/patients/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
    }
}