<template>
<div>
<transition name="fade">
<div
>
    <b-row>
        <b-col>
            <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                >
            <b-form
                class="form-opd-primary"
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
                >
            
            <b-card no-body class="p-2">
                <h4>ข้อมูลทั่วไป (ซักประวัติโดย: <span class="info">{{history_taker}}</span>)</h4>
                <b-row class="mt-2">
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="first_name"
                        rules="required"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ชื่อ :"
                            >
                                <b-form-input
                                    id="first_name"
                                    v-model="Data.first_name"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="last_name"
                        rules="required"
                        >
                            <b-input-group
                            size="sm"
                            prepend="นามสกุล :"
                            >
                                <b-form-input
                                    id="last_name"
                                    v-model="Data.last_name"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                            #default="validationContext"
                            name="gender"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="เพศ"
                            label-for="gender"
                            >
                                <b-form-select
                                    :options="options_gender"
                                    id="gender"
                                    v-model="Data.gender"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="age"
                        >
                            <b-input-group
                            size="sm"
                            prepend="อายุ :"
                            >
                                <b-form-input
                                    id="age"
                                    v-model="Data.age"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="education"
                        >
                            <b-input-group
                            size="sm"
                            prepend="การศึกษา :"
                            >
                                <b-form-input
                                    id="education"
                                    v-model="Data.education"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="birthdate"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ด/ว/ป เกิด :"
                            >
                                <b-form-input
                                    id="birthdate"
                                    v-model="Data.birthdate"
                                    autofocus
                                    type="date"
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider
                        #default="validationContext"
                        name="NationalIDNumber"
                        >
                            <b-input-group
                            size="sm"
                            prepend="เลขที่บัตรประชาชน :"
                            >
                                <b-form-input
                                    id="NationalIDNumber"
                                    v-model="Data.NationalIDNumber"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="career"
                        >
                            <b-input-group
                            size="sm"
                            prepend="อาชีพ :"
                            >
                                <b-form-input
                                    id="career"
                                    v-model="Data.career"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>

                    </b-col>
                    <b-col md="9">
                        <validation-provider
                        #default="validationContext"
                        name="know_from"
                        >
                            <b-input-group
                            size="sm"
                            prepend="รู้จักวีว่าคลินิคจาก :"
                            >
                                <b-form-input
                                    id="know_from"
                                    v-model="Data.know_from"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>

                    </b-col>
                </b-row>
                <h4 class="mt-2">ข้อมูลทั่วไป (ซักประวัติโดย: <span class="info">{{history_taker}}</span>)</h4>
                <b-row class="mt-2">
                    <b-col md="3">

                        <validation-provider
                        #default="validationContext"
                        name="last_menstrual_period"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ประจำเดือนครับสุดท้าย :"
                            >
                                <b-form-input
                                    id="last_menstrual_period"
                                    v-model="Data.last_menstrual_period"
                                    autofocus
                                    type="date"
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="pregnant"
                        >
                            <b-input-group
                            size="sm"
                            prepend="กำลังตั้งครรภ์ :" append="เดือน"
                            >
                                <b-form-input
                                    id="pregnant"
                                    v-model="Data.pregnant"
                                    type="number"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="breastfeed"
                        >
                            <b-input-group
                            size="sm"
                            prepend="กำลังให้นมบุตร  :"
                            >

                            <b-form-checkbox
                            class="ml-2"
                            id="breastfeed"
                            v-model="Data.breastfeed"
                            name="breastfeed"
                            :value=1
                            :unchecked-value=0
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            >
                            </b-form-checkbox>

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="weight"
                        rules="min:0"
                        >
                            <b-input-group
                            size="sm"
                            prepend="น้ำหนัก :" append="กก."
                            >
                                <b-form-input
                                    id="weight"
                                    v-model="Data.weight"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="height"
                        rules="min:0"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ส่วนสูง :" append="ซม."
                            >
                                <b-form-input
                                    id="height"
                                    v-model="Data.height"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="BMI"
                        rules="min:0"
                        >
                            <b-input-group
                            size="sm"
                            prepend="BMI :"
                            >
                                <b-form-input
                                    id="BMI"
                                    v-model="Data.BMI"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider
                        #default="validationContext"
                        name="congenital_disease"
                        >
                            <b-input-group
                            size="sm"
                            prepend="โรคประจำตัว/การเจ็บป่วยในอดีต :"
                            >
                                <b-form-input
                                    id="congenital_disease"
                                    v-model="Data.congenital_disease"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>

                    </b-col>
                    <b-col md="5">
                        <validation-provider
                        #default="validationContext"
                        name="treating_disease"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ขณะนี้กำลังรักษาโรค :"
                            >
                                <b-form-input
                                    id="treating_disease"
                                    v-model="Data.treating_disease"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>

                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="taking_medicine"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ขณะนี้กำลังรับประทานยา/วิตามิน :"
                            >
                                <b-form-input
                                    id="taking_medicine"
                                    v-model="Data.taking_medicine"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>

                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="drug_allergy"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ประวัติการแพ้ยา/ผลข้างเคยใช้จากยา  :"
                            >
                                <b-form-input
                                    id="drug_allergy"
                                    v-model="Data.drug_allergy"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>

                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="skin_treatment"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ประวัติการรักษาผิวพรรณ  :"
                            >
                                <b-form-input
                                    id="skin_treatment"
                                    v-model="Data.skin_treatment"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>

                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="skin_treatment_place"
                        >
                            <b-input-group
                            size="sm"
                            prepend="เคยรักการรักษาผิวพรรณที่  :"
                            >
                                <b-form-input
                                    id="skin_treatment_place"
                                    v-model="Data.skin_treatment_place"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>

                    </b-col>
                    <b-col md="6">
                        <validation-provider
                        #default="validationContext"
                        name="how_treatment"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ด้วยวิธี  :"
                            >
                                <b-form-input
                                    id="how_treatment"
                                    v-model="Data.how_treatment"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>

                    </b-col>
                    
                    <b-col md="12">
                        <validation-provider
                        #default="validationContext"
                        name="acne_treatment_products"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ขณะนี้ใช้ผลิตภัณฑ์ รักษาสิว :"
                            >
                                <b-form-input
                                    id="acne_treatment_products"
                                    v-model="Data.acne_treatment_products"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="blemishes"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ฝ้า :"
                            >
                                <b-form-input
                                    id="blemishes"
                                    v-model="Data.blemishes"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="maintain"
                        >
                            <b-input-group
                            size="sm"
                            prepend="บำรุง :"
                            >
                                <b-form-input
                                    id="maintain"
                                    v-model="Data.maintain"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="sun_protection"
                        >
                            <b-input-group
                            size="sm"
                            prepend="กันแดด :"
                            >
                                <b-form-input
                                    id="sun_protection"
                                    v-model="Data.sun_protection"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider
                        #default="validationContext"
                        name="clean"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ทำความสะอาด :"
                            >
                                <b-form-input
                                    id="clean"
                                    v-model="Data.clean"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider
                        #default="validationContext"
                        name="sign_recipient"
                        >
                            <b-input-group
                            size="sm"
                            prepend="ลงนามผู็รรับการรักษา :"
                            >
                                <b-form-input
                                    id="sign_recipient"
                                    v-model="Data.sign_recipient"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider
                        #default="validationContext"
                        name="sign_date"
                        >
                    
                            <b-input-group
                            size="sm"
                            prepend="........วันที่ :"
                            >
                                <b-form-input
                                    id="sign_date"
                                    v-model="Data.sign_date"
                                    type="date"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                <!--<b-row class="mb-1">
                    <b-col md="12">
                        <validation-provider
                            #default="validationContext"
                            name="smoke"
                            rules=""
                        >
                        <b-form-group label="สูบบุหรี่:">
                        <b-form-radio-group
                            id="smoke"
                            v-model="Data.smoke"
                        >
                            <b-form-radio value=0 
                            >
                            ไม่สูบ</b-form-radio>
                            <b-form-radio value=1
                            >สูบบางครั้ง</b-form-radio>
                            <b-form-radio value=2
                            >สูบ</b-form-radio>
                        </b-form-radio-group>
                        </b-form-group>
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col md="12">
                        <validation-provider
                            #default="validationContext"
                            name="alcohol"
                            rules=""
                        >
                        <b-form-group label="ดื่มสุรา:">
                        <b-form-radio-group
                            id="alcohol"
                            v-model="Data.alcohol"
                        >
                            <b-form-radio value=0 
                            >
                            ไม่ดื่ม</b-form-radio>
                            <b-form-radio value=1
                            >
                            ดื่มบางครั้ง</b-form-radio>
                            <b-form-radio value=2
                            >
                            ดื่ม</b-form-radio>
                        </b-form-radio-group>
                        </b-form-group>
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>
                </b-row>
                
                <h4><span class="dot"></span> การตรวจร่างกาย</h4>
                <b-row class="mt-2">
                    <b-col md="6">
                        <validation-provider
                        #default="validationContext"
                        name="PE"
                        >
                            <b-input-group
                            size="sm"
                            prepend="PE :"
                            >
                                <b-form-input
                                    id="PE"
                                    v-model="Data.PE"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider
                        #default="validationContext"
                        name="GA"
                        >
                            <b-input-group
                            size="sm"
                            prepend="GA :"
                            >
                                <b-form-input
                                    id="GA"
                                    v-model="Data.GA"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                <h4 class="mt-2"><span class="dot"></span> การวินิจฉัยโรค</h4>
                <b-row class="mt-2">
                    <b-col md="12">
                        <validation-provider
                        #default="validationContext"
                        name="DX"
                        >
                            <b-input-group
                            size="sm"
                            prepend="DX :"
                            >
                                <b-form-textarea
                                    id="DX"
                                    v-model="Data.DX"
                                    autofocus
                                    rows="10"
                                    max-rows="20"
                                    :state="getValidationState(validationContext)"
                                    trim
                                   
                                
                                />
                        
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                                
                            </b-input-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                -->
                <!-- Form Actions -->
            <div class="d-inline-block m-2">
                <b-button
                :disabled="loading"
                variant="transpant"
                class="mr-2 border-dark text-Secondary float-left"
                type="button"
                @click="hide"
                >
                ยกเลิก
                </b-button>
                <b-button
                :disabled="loading"
                variant="transpant"
                class="mr-2 border-success text-success float-right"
                type="submit"
                >
                บันทึก
                </b-button>
            </div>
            </b-card>
        </b-form>
            </validation-observer>
        </b-col>
    </b-row>
</div>
</transition>
</div>
</template>
<script>
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,
  BButton
 } from 'bootstrap-vue'
 import {
   BSidebar, BBadge, BForm, BFormGroup, BFormRadioGroup, BInputGroup, BFormInput, BFormRadio, BFormTextarea, BFormCheckbox, BFormInvalidFeedback
 } from 'bootstrap-vue'
 import PatientsCard from '../../patients/patients-detail/PatientsCard.vue'
 import { ref,onUnmounted,beforeMount  } from '@vue/composition-api'
 import Ripple from 'vue-ripple-directive'
 import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
 import router from '@/router'
 import store from '@/store'
 import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QueueForm from '../../queue/queue-form/QueueForm.vue'
import opdStoreModule from '../opdStoreModule'
//validate
 import { ValidationProvider, ValidationObserver } from 'vee-validate'
 import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'

 export default {
    directives: {
        Ripple,
    },
    model: {
    prop: 'isOpdFormSidebarActive',
    event: 'update:is-opd-form-sidebar-active',
    },
    props: {
    isOpdFormSidebarActive: {
      required: true,
    },
    opdData: {
      type: Object,
      required: true,
    },
    main_opdcard_id:{
        type: Number,
    },
    patient_id:{
        type:Number,
    }
    },
    components:{
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,
        BButton,BInputGroup,BBadge,
        PatientsCard,
        FeatherIcon,ValidationProvider, ValidationObserver,
        BSidebar, BForm, BFormGroup, BFormRadioGroup, BFormInput, BFormRadio, BFormTextarea, BFormCheckbox, BFormInvalidFeedback,
        QueueForm,BFormSelect
    },
    setup(props, { emit }){

        const loading = ref(false);
        const userStorage = JSON.parse(localStorage.getItem('userData'));
        const history_taker = userStorage.first_name +' '+ userStorage.last_name;

        const id = ref(0);
        if(props.patient_id !== undefined){
            id.value = props.patient_id;
         
        }
        else{
            id.value = router.currentRoute.params.id;
        }

        const options_gender = ref([
                { value: null, text: 'Please select an option' },
                { value: 'male', text: 'ชาย' },
                { value: 'female', text: 'หญิง' },
                { value: 'lgbq', text: 'LGBQ' },
            ]);
       
    
       const toast = useToast()
       //const QueueData = ref({});
       
       const blankData = {
        patient_id:'',
        first_name:'',
        last_name:'',
        gender:'',
        age:'',
        career:'',
        education:'',
        birthdate:'',
        NationalIDNumber:'',
        last_menstrual_period:'',
        pregnant:'',
        breastfeed:'',
        weight:'',
        height:'',
        BMI:'',
        congenital_disease:'',
        treating_disease:'',
        taking_medicine:'',
        drug_allergy:'',
        skin_treatment:'',
        skin_treatment_place:'',
        how_treatment:'',
        know_from:'',
        acne_treatment_products:'',
        blemishes:'',
        maintain:'',
        sun_protection:'',
        clean:'',
        sign_recipient:'',
        sign_date:'',
        created_by:''
       }
       const Data = ref(JSON.parse(JSON.stringify(blankData)))
       const resetData = () => {
        Data.value = JSON.parse(JSON.stringify(blankData))
        }
        if(props.opdData){ 
            Data.value  = JSON.parse(JSON.stringify(props.opdData))

            Data.value.last_menstrual_period = Data.value.last_menstrual_period?Data.value.last_menstrual_period.split('T')[0]:Data.value.last_menstrual_period;
        }

        console.log('props.opdData',props.opdData);
        console.log('Data.value',Data.value);


        const PATIENTS_STORE_MODULE_NAME = 'app-opd-form';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, opdStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });


        /*store.dispatch('app-opd-form/fetchPatientData', {id:id.value})
          .then((response) => {
            // const dates = new Date();
            Data.value.first_name = response.data.data[0].first_name;
            Data.value.last_name = response.data.data[0].last_name;
            Data.value.gender = response.data.data[0].gender;
            Data.value.age = response.data.data[0].age;
            Data.value.career = response.data.data[0].career;
            Data.value.education = response.data.data[0].education;
            Data.value.birthdate = response.data.data[0].birthdate;
            Data.value.know_from = response.data.data[0].know_from;
            Data.value.NationalIDNumber = response.data.data[0].NationalIDNumber;
            Data.value.sign_recipient = response.data.data[0].first_name+' '+response.data.data[0].last_name;
            Data.value.sign_date = new Date().toISOString().slice(0,10);

    
          })*/

       const onSubmit = () => {
        //wait api finish
        loading.value = true
      //default 
      Data.value.patient_id = id.value;
      Data.value.branch_id = userStorage.branch_id;
      Data.value.created_by = userStorage.id;
      //

      if(Data.value.is_active == true){
        Data.value.is_active = 1;
      }
      else if(Data.value.is_active == false){
        Data.value.is_active = 0;
      }

        if(Data.value.id){
          store.dispatch('app-opd-form/updateOpdCardData', Data.value)
          .then((response) => {

            loading.value = false
            emit('update:is-opd-form-sidebar-active', false)
            emit('update:is-queue-form-sidebar-active', true)
            toast({
            component: ToastificationContent,
            props: {
              title: "Save Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          }).catch(()=>{
            loading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })

        }else{
          store.dispatch('app-opd-form/addData', Data.value)
          .then((res) => {
            if(res.data.success){
                    
                const opd_id = res.data.data.insertId;
                
                let odpcard = {
                    opd_id:opd_id,
                    patient_id:id.value,
                    status:'0',
                    check_status:'0',
                    created_by:userStorage.id,
                    branch_id:userStorage.branch_id,
                    ...Data.value
                }
                
                //check add sub opdcard
                if(props.main_opdcard_id != 0 && props.main_opdcard_id){
                    odpcard.opdcard_id = props.main_opdcard_id;
                }
                store.dispatch('app-opd-form/addOpdCardData', odpcard)
                .then((response) => {
  
                    if(response.data.success){
                        props.opdData.id = response.data.data.insertId;
                        loading.value = false
                        emit('update:is-opd-form-sidebar-active', false)
                        emit('update:is-queue-form-sidebar-active', true)
                        emit('update:main_opdcard_id', response.data.data.insertId)
                        toast({
                        component: ToastificationContent,
                        props: {
                        title: "Save Success",
                        icon: 'AlertTriangleIcon',
                        variant: 'success',
                        },
                        })
                    }
                    else{
                        loading.value = false
                        toast({
                            component: ToastificationContent,
                            props: {
                            title: "Error Save",
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                            },
                        })
                    }
                }).catch(()=>{
                    loading.value = false
                toast({
                    component: ToastificationContent,
                    props: {
                    title: "Error Save",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    },
                })
            
                })

                toast({
                component: ToastificationContent,
                props: {
                title: "Save Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
                },
            })
        }
        else{
            loading.value = false
            toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
          }).catch(()=>{
            loading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })

        }
        }
        const {
        refFormObserver,
        getValidationState,
        resetForm,
        } = formValidation(resetData)

        const hide = ()=>{
        emit('update:is-queue-form-sidebar-active',false)
        emit('update:is-opd-form-sidebar-active', false)
        emit('update:is-opd-detail-sidebar-active', true)
        emit('refetch-data')
        }

        return {
            loading,
            id,
            refFormObserver,
            getValidationState,
            resetForm,
            onSubmit,
            Data,
            history_taker,
            hide,
            options_gender
        }
        
    },
 }
</script>
<style>
.dot {
  height: 12px;
  width: 12px;
  background-color: #00BFFB;
  border-radius: 50%;
  display: inline-block;
}
</style>