<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col lg="12" class="text-center mb-2">
                        <div>
                            <div class="d-inline-block upload-img">
                            <b-img
                              
                                v-if="Data.avatar"
                                :src="Data.avatar"
                                height="120"
                                width="120"
                                class="rounded-circle "
                            />
                            <b-img
                              
                                v-else
                                src="@/assets/images/no-img.png"
                                height="120"
                                width="120"
                                class="rounded-circle "
                            />
                            
                          </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" class="">
                        <h4 class="text-center font-weight-bold c-blue-header">{{Data.code_member}} {{Data.prefix}} {{Data.first_name}} {{Data.last_name}}</h4>
                    </b-col>
                </b-row>
                <!--<b-row>
                    <b-col lg="12" class="pb-1">
                        <div class="rounded-pill w-50 mx-auto" style="background-color:#AAD5E5;">
                            <h5 class="text-center p-1 font-weight-bold" style="color:#2A6E97;">{{Data.member_status}}</h5>
                        </div>
                    </b-col>
                </b-row>-->
                <b-tabs>
                    <b-tab title="ข้อมูล">
                        <b-row class="breakline-top breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">เลขบัตรประชาชน:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.NationalIDNumber}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">เพศ:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.gender}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">เบอร์โทร:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.phone}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">แต้มสะสม:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.point}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">เปิดใบเสร็จล่าสุด:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.updated_at}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">ตรวจล่าสุด:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.updated_at}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">สร้างเมื่อ:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.created_at}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">แก้ไขล่าสุด:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.updated_at}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">กรุ๊ปเลือด:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.blood_type}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">แพ้ยา:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.drug_allergy}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">โรคประจำตัว:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.medical_condition}}</span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">หมายเหตุ:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.remark}}</span>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="แบบสอบถาม">
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">อาชีพ:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.career}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">การศึกษา:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.education}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">รายได้:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.income}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">ที่อยู่:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.address}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">รู้จักวีว่าคลินิกจาก:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.know_from}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">สนใจบริการ:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.interested_services}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">วันที่สะดวก:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.convenient_day}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">เวลาที่สะดวก:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.convenient_time}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">เคยรับการรักษาผิวพรรณที่:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.skin_treatment_place}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">ด้วยวิธี:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.skin_treatment_how}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">ขณะนี้ใช้ผลิตภัณฑ์รักษาสิว:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.skin_treatment}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">ฝ้า:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.skin_treatment_blemishes}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">บำรุง:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.skin_treatment_maintain}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">กันแดด:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.skin_treatment_sun_protection}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">ทำความสะอาด:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{Data.skin_treatment_clean}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">ลายเซ็นต์ยินยอม:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold"><img :src="Data.sign_image2_fullpath" class="w-50"/></span>
                            </b-col>
                        </b-row>
                        <b-row class="breakline-bottom">
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">วันที่เซ็นต์:</span>
                            </b-col>
                            <b-col lg="6" class="pt-1 pb-1">
                                <span class="c-gray-text font-weight-bold">{{new Date(Data.sign_date).toLocaleDateString('th')}}</span>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </div>
        </b-card>
    </div>
</template>
<script>
import { BBreadcrumb } from 'bootstrap-vue'
 import {
   BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab, BFormSelect
 } from 'bootstrap-vue'
 //upload
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol
 } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref,onUnmounted } from '@vue/composition-api'
import store from '@/store'
import patientsStoreModule from '../patientsStoreModule'


 import Ripple from 'vue-ripple-directive'
 import router from '@/router'

 export default {
    directives: {
        Ripple,
    },
    components:{
        BBreadcrumb,
        BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab,
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,
        ValidationProvider,
        ValidationObserver
    },
    data() {
      return {

      }
    },

    setup(props){
        const Data = ref({});
        const userStorage = JSON.parse(localStorage.getItem('userData'));
        const PATIENTS_STORE_MODULE_NAME = 'app-patients';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, patientsStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const id = ref(router.currentRoute.params.id);
        store.dispatch('app-patients/fetchData', {id:id.value})
           .then((res) => {
                console.log(res.data.data[0]);
                Data.value = res.data.data[0];
            });

        return {
            Data
        }
        
    }
 }
</script>
<style>
.c-blue-header{
    color:#04497C !important;
}
.c-gray-text{
    color:#808080 !important;
}
.breakline-top{
    border-top:1px solid #D9D9D9 !important;
}
.breakline-bottom{
    border-bottom:1px solid #D9D9D9 !important;
}
</style>