import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchQueueData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
          console.log('data',data);
            return new Promise((resolve, reject) => {
              axios
                .post('/queue/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        updateQueueData(ctx,queryParams){
          var data = queryParams;
            
          return new Promise((resolve, reject) => {
            axios
              .put(`/queue/v1/update/${queryParams.id}`,data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        fetchOpdData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/opd/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        //opd_card_image
        fetchOpdcardImageData(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post(`/opdcard/image/v1/getall`,data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        fetchOpdcardRelateImageData(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post(`/opdcard/image/v1/getall`,data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        deleteOpdcardImageData(ctx,queryParams){

            
          return new Promise((resolve, reject) => {
            axios
              .delete(`/opdcard/image/v1/delete/${queryParams.id}`)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        updateOpdcardImageData(ctx,queryParams){
          var data = queryParams;
            
          return new Promise((resolve, reject) => {
            axios
              .put(`/opdcard/image/v1/update/${data.id}`,data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        addOpdcardImageData(ctx,queryParams){
          var data = queryParams;
            
          return new Promise((resolve, reject) => {
            axios
              .post('/opdcard/image/v1/add',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },

        
        //opd_card_item
        fetchOpdcardItemData(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post('/opdcard/item/v1/getall',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        fetchOpdcardItemDeleteData(ctx,queryParams){

            
          return new Promise((resolve, reject) => {
            axios
              .delete('/opdcard/item/v1/delete/'+queryParams.id)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },

        //update opdcard_item course_item meterial_item , delete medicine_item
        deleteMedicineItemData(ctx,queryParams){
              
          return new Promise((resolve, reject) => {
            axios
              .delete('/medicines/item/v1/delete/'+queryParams.id)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
          
        },
        updateMeterialItemData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .put(`/meterial/item/v1/update/${queryParams.id}`,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        updateCourseItemData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .put(`/courses/item/v1/update/${queryParams.id}`,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchCourseItemData(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post('/courses/item/v1/getall',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },

        //opdcard
        fetchOpdcardData(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post('/opdcard/v1/getall',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        updateDescriptionOpdcardData(ctx,queryParams){
          var data = JSON.stringify(queryParams);

          return new Promise((resolve, reject) => {
            axios
              .put(`/opdcard/v1/description/update/${queryParams.id}`,data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
      },

      async addQueueServiceData(ctx,queryParams){
        var data = queryParams;

        return new Promise((resolve, reject) => {
            axios
              .post('/queue/service/v1/add',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })

    },

    
    }
}