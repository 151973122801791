<template>
    <div>
        <b-card no-body
        >
            <div class="m-2">
                <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                >
                <b-form
                    class="form-blue"
                    @reset.prevent="resetForm"

                >
                <b-row v-if="Image_Data.image">
                    <b-col class="mr-2 mb-2" lg="2" v-for="i,index in Image_Data.image.length">
                        <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                        >
                        <b-media-aside>
                          <div class="d-inline-block ">
                            <div v-if="Image_Data.image[index]">
                            <b-img
                                ref="refPreviewEl"
                                :src="Image_Data.image[index]"
                                height="200"
                                width="200"
                                class="mr-2 mb-1 mb-md-0"
                            />
                            <b-button style="right: 0;position: absolute;" @click="removeImage(Image_Data.id[index])" size="sm" variant="outline-danger">Remove image</b-button>
                            </div>
                            <div v-else>
                            <b-img
                                ref="refPreviewEl"
                                height="200"
                                width="200"
                                class="mr-2 mb-1 mb-md-0"
                            />
                            </div>
                          </div>
                        </b-media-aside>
                      </b-media>
                    </b-col>
                    <b-col class="mr-2 mb-2" lg="2" v-for="i in (10-Image_Data.image.length)">
                        <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                        >
                        <b-media-aside>
                          <div class="d-inline-block ">
                            <div>
                            <b-img
                                ref="refPreviewEl"
                                height="200"
                                width="200"
                                class="mr-2 mb-1 mb-md-0"
                            />
                            </div>
                          </div>
                        </b-media-aside>
                      </b-media>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col class="mr-2 mb-2" lg="2" v-for="i in (10)">
                        <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                        >
                        <b-media-aside>
                          <div class="d-inline-block ">
                            <div>
                            <b-img
                                ref="refPreviewEl"
                                height="200"
                                width="200"
                                class="mr-2 mb-1 mb-md-0"
                            />
                            </div>
                          </div>
                        </b-media-aside>
                      </b-media>
                    </b-col>
                </b-row>
                <b-row class="mt-5 mb-2">
                    <b-col>
                        <div class="title">อัพโหลดรูปภาพ จำกัด 10 รูป</div>
                            <b-form-file
                            ref="refInputEl"

                            class="rounded-circle"
                            size="sm"
                            v-model="Data.imagesFile"
                            accept=".jpg, .png, .gif"
                            @input="inputImageRenderer"
                            />
                    </b-col>
                </b-row>
                 <b-button
                   v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                   variant="primary"
                   class="mr-2"
                   type="button"
                   @click="onSubmit"
                 >
                   Submit
                 </b-button>

                </b-form>
                </validation-observer>
               
            </div>
           
        </b-card>
    </div>
    </template>
    <script>
    import { BBreadcrumb } from 'bootstrap-vue'
    import router from '@/router'
     import {
       BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab, BFormSelect
     } from 'bootstrap-vue'
     //upload
     import {
      BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol
     } from 'bootstrap-vue'
    
    //validation
     import { ValidationProvider, ValidationObserver } from 'vee-validate'
     import { ref,onUnmounted } from '@vue/composition-api'
     import { required, alphaNum, email, password,confirmed } from '@validations'
     import formValidation from '@core/comp-functions/forms/form-validation'
     import Ripple from 'vue-ripple-directive'
     import store from '@/store'
 
     import useRoomDetail from './useRoomDetail.js'
     import roomDetailStoreModule from './roomDetailStoreModule.js'

     //file
     import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
     import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import { useToast } from 'vue-toastification/composition'
    export default {
        components:{
            BBreadcrumb,
            BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab,
            BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
        },
        props: {
            opdcard_id:{
            required: true,
            },
        },
        setup(props, { emit }){
            const queue_id = ref(router.currentRoute.params.queue_id);
            const userStorage = JSON.parse(localStorage.getItem('userData'));
            const toast = useToast()
            const PATIENTS_STORE_MODULE_NAME = 'app-roomdetail';
            if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, roomDetailStoreModule)
            onUnmounted(() => {
                if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
            });
            const {

                fetchOpdcardImageData,
                deleteOpdcardImageData,
                Image_Data,

            } = useRoomDetail(0,props.opdcard_id);

            const blankData = {
            
               id:'',
               opdcard_id:props.opdcard_id,
               created_by:userStorage.id,
               ref:'viva',
               image:[],
               imagesFile:null,
               
              
           }

            const Data = ref(JSON.parse(JSON.stringify(blankData)))
            
            const resetData = () => {
               Data.value = JSON.parse(JSON.stringify(blankData))
            }


           const onSubmit = () => {
            //uploadimage
            if(refPreviewEl.value[0].src){
                for(let i=0;i<refPreviewEl.value.length;i++){
                    if(refPreviewEl.value[i].src){
                        console.log('refInputEl ',refInputEl.value.files[i])
                        Data.value.files = refInputEl.value.files[i];
                    }
                    
                }
                
            }
            let formData = new FormData();
            for(const [key, value] of Object.entries(Data.value)) {
              formData.append(key, value); 
            }

            if(Data.value.id){

                store.dispatch('app-roomdetail/updateOpdcardImageData', formData)
                .then((res) => {
                   if(res.data.success){
                    fetchOpdcardImageData()
                    toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                
                   }else{
                   
                    toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               }).catch((error) =>{
                toast({
                    component: ToastificationContent,
                    props: {
                    title: "Error update",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: error,
                    },
                })
            
                })
            }else{

                store.dispatch('app-roomdetail/addOpdcardImageData', formData)
               .then((res) => {
                   if(res.data.success){
                    fetchOpdcardImageData()
                    toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });

                   }else{
                   
                    toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               })

            }
            
           }
           //uploadimage
           const refInputEl = ref(null);
            const refPreviewEl = ref(null);
             function inputImageRenderer(){
                refInputEl.value.files.forEach(value => { 
                    const file = value
                    const reader = new FileReader()

                    reader.addEventListener(
                    'load',
                    () => {

                        for(let i=0;i<refPreviewEl.value.length;i++){
                        if(!refPreviewEl.value[i].src){
                            console.log('refPreviewEl',value.name)
                            refPreviewEl.value[i].src = reader.result //base64

                            break
                        }
                    }
                    },
                    false,
                    )

                    if (file) {
                    reader.readAsDataURL(file)
                    }
   
            })
            }

            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetData)
            return {

                Data,
                resetData,
                refFormObserver,
                getValidationState,
                resetForm,
                onSubmit,
                inputImageRenderer,
                refInputEl,
                refPreviewEl,
                fetchOpdcardImageData,
                deleteOpdcardImageData,
                Image_Data,
            }
    
        },methods:{
        handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
        },
        removeImage(id){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete image: ', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
           this.deleteOpdcardImageData(id)
          }
        })

        },
    }
        
    }
    </script>