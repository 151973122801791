<template>
  <b-sidebar
    id="drop-item-form-sidebar"
    :visible="isItemFormSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-sm"
    shadow
    backdrop
    no-header
    right
    @shown="getData(ItemData),updatetable(),fetchMedicineData(),fetchCourseMedicineData(),fetchMeterialData(),fetchCourseItemData(),fetchMedicineItemData()"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-item-form-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{headertext}}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
     
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row :class="`mb-4 d-${select_display}`">
              <b-col>
                 
                  <b-form-group
                      :label="headertext"
                      label-for="status"
                      >
                      <v-select
                          v-if="Options===1"
                          :options="course_item_Data"
                          v-model="Data.course_id"
                          :get-option-label="(option) => option.title"
                          :reduce="val => val.course_id"
                          @input="addtable(Data.course_id)"
                          :disabled="loading"
                          >
                          <template #option="{ title,category_name,type_name,price,wage,commission,balance }">
                          {{ title }}
                          <br />
                          <cite v-if="balance">คงเหลือ: {{balance}}</cite>
                          <br />
                          <cite v-if="category_name">หมวดหมู่: {{category_name}}</cite>
                          <cite v-if="type_name">ประเภท: {{type_name}}</cite>
                          <cite v-if="price">ค่าบริการ: {{price}}</cite>
                          <cite v-if="wage">ค่าแพทย์: {{wage}}</cite>
                          <cite v-if="commission">ค่ามือพนักงาน: {{commission}}</cite>
                          </template>
                      </v-select>
                      <div v-else-if="Options===2">
                      <h5 class="mb-0">
                        หมวดหมู่
                      </h5>
                      <v-select
                          :options="medicine_category_Data.filter(item => item.group_id === 1)"
                          v-model="Data.medicine_category_id"
                          :get-option-label="(option) => option.title"
                          :reduce="val => val.id"
                          @input="addtable(Data.medicine_category_id,Data.medicine_type_id)"
                          :disabled="loading"
                          >
                          <template #option="{ title }">
                          {{ title }}
                          </template>
                      </v-select>
                      </div>

                      <v-select
                          v-else-if="Options===3"
                          :options="meterial_Data"
                          v-model="Data.meterial_id"
                          :get-option-label="(option) => option.title"
                          :reduce="val => val.id"
                          @input="addtable(Data.meterial_id)"
                          :disabled="loading"
                          >
                          <template #option="{ title,category_name,type_name,price,wage,commission }">
                          {{ title }}
                          <br />
                          <cite v-if="category_name">หมวดหมู่: {{category_name}}</cite>
                          <cite v-if="type_name">ประเภท: {{type_name}}</cite>
                          <cite v-if="price">ค่าบริการ: {{price}}</cite>
                          <cite v-if="wage">ค่าแพทย์: {{wage}}</cite>
                          <cite v-if="commission">ค่ามือพนักงาน: {{commission}}</cite>
                          </template>
                      </v-select>
                      
                  </b-form-group>
                  
              </b-col>
          </b-row>
          <template v-if="Options===1">
          <b-row class="mt-2">
              <b-col md="4">
                รายการ
              </b-col>
              <b-col md="4">
                คงเหลือ
              </b-col>
              <b-col md="4">
                จำนวนที่ใช้
              </b-col>
          </b-row>
          <div v-for="(item,index) in Data.muti_selected" :key="'muti-'+index">
          <b-row class="mt-2">
              <b-col md="4">
                  <label for="sb-inline">
                    <h5 :id="`medicine-stock-title-${index}`" class="text-dark d-inline" >{{item[0]}}:</h5>
                  </label>
                  <b-tooltip :target="`medicine-stock-title-${index}`" triggers="hover" placement="top">
                      <small v-if="Data.muti_selected[index][3]">หน่วย: {{Data.muti_selected[index][4]}} {{Data.muti_selected[index][3]}} ต่อ 1 {{Data.muti_selected[index][2]}}</small>
                      <small v-else>หน่วย: 1 {{Data.muti_selected[index][2]}}</small>
                  </b-tooltip>
              </b-col>
              <b-col md="4">
                  <b-form-input :disabled="loading" type="number" v-model="Data.stock_out[index]"></b-form-input>
              </b-col>
              <b-col md="4">
              <b-input-group>
                <b-input-group-append>
                  <b-button :disabled="loading" :id="`tooltip-target-${index}`" variant="outline-info" @click="Minus(index)">+</b-button>
                </b-input-group-append>
              </b-input-group>
                <b-tooltip :target="`tooltip-target-${index}`" triggers="hover" placement="top">
                  <label for="sb-inline">
                    <b-badge
                        v-if="item[8]"
                        pill
                        :variant="`light-${resolveMeterialVariant(item[9])}`"
                        class="text-capitalize"
                    >
                    <feather-icon :icon="resolveMeterialGroupnameVariant(item[9])" />
                    <div v-if="item[9] == 3" class="d-inline">วัสดุ: {{item[8]}}</div>
                    <div v-if="item[9] == 4" class="d-inline">บริการ: {{item[8]}}</div>
                    
                    </b-badge>
                    <b-badge
                        pill
                        :variant="`light-${resolveMedicineVariant(item[1])}`"
                        class="text-capitalize"
                    >
                    <feather-icon :icon="resolveMedicineGroupnameVariant(item[1])" />
                    {{item[1]}}
                    </b-badge>
                  </label>
                </b-tooltip>
              </b-col>
          </b-row>
          </div>
          </template>
          <template v-if="Options===1">
            <div class="mt-2 border-top">
            <div v-for="(item,index) in Data.stock_out" :key="'stock-out'+index">
              <b-row class="mt-2">
                <b-col md="4">
                    <label for="sb-inline">
                      <h5 :id="`medicine-title-${index}`" class="text-dark d-inline" >{{Data.muti_selected[index][0]}}:</h5>
                    </label>
                    <b-tooltip :target="`medicine-title-${index}`" triggers="hover" placement="top">
                      <small v-if="Data.muti_selected[index][3]">หน่วย: {{Data.muti_selected[index][4]}} {{Data.muti_selected[index][3]}} ต่อ 1 {{Data.muti_selected[index][2]}}</small>
                      <small v-else>หน่วย: 1 {{Data.muti_selected[index][2]}}</small>
                    </b-tooltip>
                </b-col>
                <b-col md="4">
                    <b-form-input :disabled="loading" type="number" v-model="Data.balance[index]" :value="0"></b-form-input>
                </b-col>
                <b-col md="4">
                <b-input-group>
                  <b-input-group-append>
                    <b-button :disabled="loading" :id="`tooltip-balance-target-${index}`" variant="outline-danger" @click="Plus(index)">-</b-button>
                  </b-input-group-append>
                </b-input-group>
                  <b-tooltip :target="`tooltip-balance-target-${index}`" triggers="hover" placement="top">
                    <label for="sb-inline">
                      <b-badge
                          v-if="Data.muti_selected[index][8]"
                          pill
                          :variant="`light-${resolveMeterialVariant(Data.muti_selected[index][9])}`"
                          class="text-capitalize"
                      >
                      <feather-icon :icon="resolveMeterialGroupnameVariant(Data.muti_selected[index][9])" />
                      <div v-if="Data.muti_selected[index][9] == 3" class="d-inline">วัสดุ: {{Data.muti_selected[index][8]}}</div>
                      <div v-if="Data.muti_selected[index][9] == 4" class="d-inline">บริการ: {{Data.muti_selected[index][8]}}</div>
                      
                      </b-badge>
                      <b-badge
                          pill
                          :variant="`light-${resolveMedicineVariant(Data.muti_selected[index][1])}`"
                          class="text-capitalize"
                      >
                      <feather-icon :icon="resolveMedicineGroupnameVariant(Data.muti_selected[index][1])" />
                      {{Data.muti_selected[index][1]}}
                      </b-badge>
                    </label>
                  </b-tooltip>
                </b-col>
              </b-row>
            </div>
            </div>
          </template>
          <template v-else-if="Options===2">
          <b-row v-for="(item,index) in Data.muti_selected" class="mt-2" :key="'mt-k'+index">
              <b-col md="4">
                  <label for="sb-inline">
                    <h5 :id="`medicine-stock-title-${index}`" class="text-dark d-inline" >{{item[0]}}:</h5>
                  </label>
                  <b-tooltip :target="`medicine-stock-title-${index}`" triggers="hover" placement="top">
                      <b-badge
                          pill
                          :variant="`light-${resolveMedicineVariant(item[1])}`"
                          class="text-capitalize"
                      >
                      <feather-icon :icon="resolveMedicineGroupnameVariant(item[1])" />
                      {{item[1]}}
                      </b-badge>
                      <small v-if="item[3]">หน่วย: {{item[4]}} {{item[3]}} ต่อ 1 {{item[2]}}</small>
                      <small v-else>หน่วย: 1 {{item[2]}}</small>
                  </b-tooltip>
              </b-col>
              <b-col md="4">
               
                  <b-form-input :disabled="loading" id="balance" size="sm" class="border-dark" min=0 v-model="Data.balance[index]" inline placeholder="0" type="number"></b-form-input>
                
                </b-col>
              <b-col md="4">
                  <b-form-radio-group :disabled="loading" v-model="Data.main_sub_unit[index]" size="sm" buttons button-variant="outline-primary">
                      <b-form-radio v-if="item[2]" :value="0">{{item[2]}}</b-form-radio>
                      <b-form-radio v-if="item[3]" :value="1">{{item[3]}}</b-form-radio>
                  </b-form-radio-group>
              </b-col>    
          </b-row>
          </template>

          <template v-else-if="Options===3">
          <b-row class="mt-2">
              <b-col md="4">
                รายการ
              </b-col>
              <b-col md="4">
                คงเหลือ
              </b-col>
              <b-col md="4">
                จำนวนที่ใช้
              </b-col>
          </b-row>
          <div v-for="(item,index) in Data.muti_selected" :key="'div1-'+index">
          <b-row class="mt-2">
              <b-col md="4">
                  <label for="sb-inline">
                    <h5 :id="`medicine-stock-title-${index}`" class="text-dark d-inline" >{{item[0]}}:</h5>
                  </label>
                  <b-tooltip :target="`medicine-stock-title-${index}`" triggers="hover" placement="top">
                      <small v-if="Data.muti_selected[index][3]">หน่วย: {{Data.muti_selected[index][4]}} {{Data.muti_selected[index][3]}} ต่อ 1 {{Data.muti_selected[index][2]}}</small>
                      <small v-else>หน่วย: 1 {{Data.muti_selected[index][2]}}</small>
                  </b-tooltip>
              </b-col>
              <b-col md="4">
                  <b-form-input :disabled="loading" type="number" v-model="Data.stock_out[index]"></b-form-input>
              </b-col>
              <b-col md="4">
              <b-input-group>
                <b-input-group-append>
                  <b-button :disabled="loading" :id="`tooltip-target-${index}`" variant="outline-info" @click="Minus(index)">+</b-button>
                </b-input-group-append>
              </b-input-group>
                <b-tooltip :target="`tooltip-target-${index}`" triggers="hover" placement="top">
                  <label for="sb-inline">
                    <b-badge
                        v-if="item[8]"
                        pill
                        :variant="`light-${resolveMeterialVariant(item[9])}`"
                        class="text-capitalize"
                    >
                    <feather-icon :icon="resolveMeterialGroupnameVariant(item[9])" />
                    <div v-if="item[9] == 3" class="d-inline">วัสดุ: {{item[8]}}</div>
                    <div v-if="item[9] == 4" class="d-inline">บริการ: {{item[8]}}</div>
                    
                    </b-badge>
                    <b-badge
                        pill
                        :variant="`light-${resolveMedicineVariant(item[1])}`"
                        class="text-capitalize"
                    >
                    <feather-icon :icon="resolveMedicineGroupnameVariant(item[1])" />
                    {{item[1]}}
                    </b-badge>
                  </label>
                </b-tooltip>
              </b-col>
          </b-row>
          </div>
          </template>
          <template v-if="Options===3">
            <div class="mt-2 border-top">
            <div v-for="(item,index) in Data.balance" :key="'balance'+index">
              <b-row class="mt-2">
                <b-col md="4">
                    <label for="sb-inline">
                      <h5 :id="`medicine-title-${index}`" class="text-dark d-inline" >{{Data.muti_selected[index][0]}}:</h5>
                    </label>
                    <b-tooltip :target="`medicine-title-${index}`" triggers="hover" placement="top">
                      <small v-if="Data.muti_selected[index][3]">หน่วย: {{Data.muti_selected[index][4]}} {{Data.muti_selected[index][3]}} ต่อ 1 {{Data.muti_selected[index][2]}}</small>
                      <small v-else>หน่วย: 1 {{Data.muti_selected[index][2]}}</small>
                    </b-tooltip>
                </b-col>
                <b-col md="4">
                    <b-form-input :disabled="loading" type="number" v-model="Data.balance[index]"></b-form-input>
                </b-col>
                <b-col md="4">
                <b-input-group>
                  <b-input-group-append>
                    <b-button :disabled="loading" :id="`tooltip-balance-target-${index}`" variant="outline-danger" @click="Plus(index)">-</b-button>
                  </b-input-group-append>
                </b-input-group>
                  <b-tooltip :target="`tooltip-balance-target-${index}`" triggers="hover" placement="top">
                    <label for="sb-inline">
                      <b-badge
                          v-if="Data.muti_selected[index][8]"
                          pill
                          :variant="`light-${resolveMeterialVariant(Data.muti_selected[index][9])}`"
                          class="text-capitalize"
                      >
                      <feather-icon :icon="resolveMeterialGroupnameVariant(Data.muti_selected[index][9])" />
                      <div v-if="Data.muti_selected[index][9] == 3" class="d-inline">วัสดุ: {{Data.muti_selected[index][8]}}</div>
                      <div v-if="Data.muti_selected[index][9] == 4" class="d-inline">บริการ: {{Data.muti_selected[index][8]}}</div>
                      
                      </b-badge>
                      <b-badge
                          pill
                          :variant="`light-${resolveMedicineVariant(Data.muti_selected[index][1])}`"
                          class="text-capitalize"
                      >
                      <feather-icon :icon="resolveMedicineGroupnameVariant(Data.muti_selected[index][1])" />
                      {{Data.muti_selected[index][1]}}
                      </b-badge>
                    </label>
                  </b-tooltip>
                </b-col>
              </b-row>
            </div>
            </div>
          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="loading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Submit
            </b-button>
            <b-button
              :disabled="loading"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BDropdown, BDropdownItem, BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,BFormSelect,BFormSelectOption,BFormDatepicker,BFormTimepicker,
  BInputGroup,BInputGroupAppend,BInputGroupPrepend,BRow, BCol,BBadge,BFormSpinbutton,BButtonGroup,BFormRadioGroup,BFormRadio,BTooltip
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref,onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import useItemDetail from './useItemDetail'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { slugify } from '@/@fake-db/utils'
import itemStoreModule from './itemStoreModule'
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    vSelect,
    BFormSelect,
    BFormDatepicker,
    BFormTimepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BRow, 
    BCol,
    BBadge,
    BFormSpinbutton,
    BButtonGroup,
    BFormSelectOption,
    BFormRadioGroup,
    BFormRadio,
    BTooltip,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isItemFormSidebarActive',
    event: 'update:is-item-form-sidebar-active',
  },
  props: {
      isItemFormSidebarActive: {
      type: Boolean,
      required: true,
      
    },
    ItemData: {
      type: Object,
    },
    headertext:{
      type:String,
      required: true,
    },
    Options:{
      type:Number,
      required: true,
    },
    patient_id:{
      type:Number,
      required: true,
    },
    opdcard_id:{
      required: true,
    },

  },
 
  setup(props, { emit }) {

    const loading = ref(false);
    const patient_id = ref(props.patient_id);
    const toast = useToast();
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const select_display = ref('block');
    const blankData = {
      
      medicine_id:[],
      balance:[],
      stock_out:[],
      main_sub_unit:[],
      muti_selected:[],
      opdcard_item_id:[],
      patient_id:props.patient_id,
      opdcard_id:props.opdcard_id,
      branch_id: userStorage.branch_id,
      created_by: userStorage.id,
   
    }

    const PATIENTS_STORE_MODULE_NAME = 'app-item';
      if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, itemStoreModule)
      onUnmounted(() => {
          if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
      });
      const {
          medicine_Data,
          medicine_category_Data,
          medicine_type_Data,
          meterial_Data,
          course_Data,
          course_item_Data,
          course_medicine_Data,
          meterial_medicine_Data,
          fetchMedicineData,
          fetchCourseItemData,
          fetchCourseMedicineData,
          fetchMeterialData,
          fetchMeterialMedicineData,
          fetchMedicineItemData,
          resolveMedicineVariant,
          resolveMedicineGroupnameVariant,
          resolveMeterialVariant,
          resolveMeterialGroupnameVariant,
      } = useItemDetail(patient_id);
      
    const Data = ref(JSON.parse(JSON.stringify(blankData)))

    const resetData = () => {
      Data.value = JSON.parse(JSON.stringify(blankData))
    }
    const getData = (data)=>{
      if(data && data.medicine_id){ 
        select_display.value = 'none';

        Data.value  = JSON.parse(JSON.stringify(data))
      }
      else{
        select_display.value = 'block';
      }
    }


    const onSubmit = async () => {
      //wait api finish
      loading.value = true

      const isAllZero = Data.value.balance.every(item => item === 0)
      if(isAllZero === true){
        loading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: "input number",
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
          return 0
        }

      if(Data.value.id){
        store.dispatch('app-item/updateMedicineItemData', Data.value)
        .then(response => {
          emit('update:is-item-form-sidebar-active', false)
          emit('refetch-data')
          fetchMedicineData();
          fetchCourseMedicineData();
          fetchMeterialData();
          fetchCourseItemData();
          fetchMedicineItemData();
          toast({
          component: ToastificationContent,
          props: {
            title: "Save Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        }).catch(()=>{
          loading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Save",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

      }
      else{
        console.log('props.Options',props.Options)
        if(props.Options == 2 || props.Options == '2'){
          for(let i=0;i<Data.value.medicine_id.length;i++){
            await store.dispatch('app-item/addOpdcardItemData',Data.value)
          .then(response=>{
            
            Data.value.opdcard_item_id.push(response.data.data.insertId)
            console.log('Data.value',Data.value)
          }).catch((error)=>{
            toast({
              component: ToastificationContent,
              props: {
                title: "Error Save",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })

          })
        }
        }
        else{
          await store.dispatch('app-item/addOpdcardItemData',Data.value)
        .then(response=>{
          
          Data.value.opdcard_item_id = response.data.data.insertId
        }).catch((error)=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

        })
        }
        
        //use course item
        Data.value.minus_balance = 1
        await store.dispatch('app-item/updateCourseItemData',Data.value)
        
        await store.dispatch('app-item/addMedicineItemData', Data.value)
        .then(response=>{
          emit('update:is-item-form-sidebar-active', false)
          emit('refetch-data')
          fetchMedicineData();
          fetchCourseMedicineData();
          fetchMeterialData();
          fetchCourseItemData();
          fetchMedicineItemData();
          toast({
          component: ToastificationContent,
          props: {
            title: "Save Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
          })

        }).catch((error)=>{
          console.log('error',error);
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }
      loading.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)


    return {
      Data,
      medicine_Data,
      medicine_category_Data,
      medicine_type_Data,
      meterial_Data,
      course_medicine_Data,
      course_Data,
      course_item_Data,
      fetchMedicineData,
      fetchCourseItemData,
      fetchCourseMedicineData,
      fetchMeterialData,
      fetchMeterialMedicineData,
      fetchMedicineItemData,
      resolveMedicineVariant,
      resolveMedicineGroupnameVariant,
      resolveMeterialVariant,
      resolveMeterialGroupnameVariant,
      fetchMeterialMedicineData,
      meterial_medicine_Data,
      onSubmit,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      getData,
      select_display,
    }
  },
  methods:{
      addtable(value){
          this.Data.medicine_id = []
          this.Data.muti_selected = []
          this.Data.balance = []
          this.Data.stock_out = []
            if(this.Options===1){//use course
              this.course_medicine_Data.forEach(element => {
                if(value == element.courses_id){
                  if(element.group_id == 3 || element.group_id == 4){
                    this.meterial_medicine_Data.forEach(item => {
                    if(element.product_id == item.meterial_id){
                      this.Data.balance.push(0)
                      this.Data.medicine_id.push(item.product_id)
                      this.Data.stock_out.push(item.stock_out)
                      this.Data.muti_selected.push([item.title,item.group_name,item.unit_name,item.sub_unit,item.num,item.id,item.unit_id,item.stock_out,element.title,element.group_id])
                    }
                  });
                  }
                  else{
                    this.Data.balance.push(0)
                    this.Data.medicine_id.push(element.product_id)
                    this.Data.stock_out.push(element.stock_out)
                    this.Data.muti_selected.push([element.title,element.group_name,element.unit_name,element.sub_unit,element.num,element.id,element.unit_id,element.stock_out])
                  }
                }
              });

            }
            else if(this.Options===2){ //use medicine
              this.medicine_Data.forEach(element => {
                if(value == element.category_id){
                    this.Data.balance.push(0)
                    this.Data.medicine_id.push(element.id)
                    this.Data.muti_selected.push([element.title,element.group_name,element.unit_name,element.sub_unit,element.num,element.id,element.unit_id])
                }
              });
            }
            else if(this.Options===3){ //use meterial
              this.meterial_medicine_Data.forEach(element => {
                if(value == element.meterial_id){
                  this.Data.medicine_id.push(element.product_id)
                  this.Data.stock_out.push(element.stock_out)
                  this.Data.balance.push(0)
                  this.Data.muti_selected.push([element.title,element.group_name,element.unit_name,element.sub_unit,element.num,element.id,element.unit_id,element.stock_out,element.category_name])
                }
              });
            }           

      },
      updatetable(){
          this.Data.muti_selected = []
          this.Data.stock_out = []
          this.Data.balance = []
            if(this.Options===1){//use course
              this.course_medicine_Data.forEach(element => {
                if(this.Data.course_id == element.courses_id){
                  if(element.group_id == 3 || element.group_id == 4){
                    this.meterial_medicine_Data.forEach(item => {
                    if(element.product_id == item.meterial_id){
                      let index = this.Data.medicine_id.findIndex(medicine_id => medicine_id === item.product_id);
                      this.Data.stock_out.push(item.stock_out-this.Data.check_balance[index])
                      this.Data.balance.push(this.Data.check_balance[index])
                      this.Data.muti_selected.push([item.title,item.group_name,item.unit_name,item.sub_unit,item.num,item.id,item.unit_id,item.stock_out,element.title,element.group_id])
                    }
                  });
                  }
                  else{
                    let index = this.Data.medicine_id.findIndex(medicine_id => medicine_id === element.product_id);
                    this.Data.stock_out.push(element.stock_out-this.Data.balance[index])
                    this.Data.balance.push(this.Data.balance[index])
                    this.Data.muti_selected.push([element.title,element.group_name,element.unit_name,element.sub_unit,element.num,element.id,element.unit_id,element.stock_out])
                  }
                }

              });
            }
            else if(this.Options===2){ //use medicine
              this.medicine_Data.forEach(element => {
                if(this.Data.medicine_id[0] == element.id){
                    this.Data.balance.push(this.Data.check_balance[0])
                    this.Data.muti_selected.push([element.title,element.group_name,element.unit_name,element.sub_unit,element.num,element.id,element.unit_id])
                }
              });
            }
            else if(this.Options===3){ //use meterial
              this.meterial_medicine_Data.forEach(element => {
                if(this.Data.meterial_id == element.meterial_id){
                    let index = this.Data.medicine_id.findIndex(medicine_id => medicine_id === element.product_id);
                    this.Data.stock_out.push(element.stock_out-this.Data.check_balance[index])
                    this.Data.balance.push(this.Data.check_balance[index])
                  this.Data.muti_selected.push([element.title,element.group_name,element.unit_name,element.sub_unit,element.num,element.id,element.unit_id,element.stock_out,element.category_name])
                }
              });
            }           

      },
      Minus(index){
        if(this.Data.stock_out[index] == 0){
          return this.Data.stock_out[index]
        }
        else{
          this.$set(this.Data.stock_out, index, this.Data.stock_out[index]-1)
          this.$set(this.Data.balance, index, this.Data.balance[index]-1)
        }
        
      },
      Plus(index){
        if(this.Data.balance[index] == 0){
          return this.Data.balance[index]
        }
        else{
          this.$set(this.Data.balance, index, this.Data.balance[index]+1)
          this.$set(this.Data.stock_out, index, this.Data.stock_out[index]+1)
        }
        
      },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#item-form-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
