<template>
    <div>
    <opd-item-form
      v-if="patient_id && opdcard_id"
      :patient_id.sync="patient_id"
      :opdcard_id.sync="opdcard_id"
      :is-item-form-sidebar-active.sync="isItemFormSidebarActive"
      :headertext.sync="headertext"
      :ItemData.sync="ItemData"
      :Options.sync="Options"
      @refetch-data="fetchOpdcardItemData" 
    />
    <check-lists
    v-if="patient_id && opdcard_id"
    :opdcard_id.sync="opdcard_id"
    :patient_id.sync="patient_id"
    :is-checks-form-sidebar-active.sync="isChecksFormSidebarActive"
    @refetch-data="fetchOpdcardItemData" 
     />
    <b-card no-body>
        <div class="m-2 p-1">
            <b-row>
                <b-col>
                    <div class="demo-vertical-spacing ml-1">
                        <b-button
                        variant="transpant"
                        class="mr-2 border-primary text-dark float-right"
                        type="button"
                        >
                        ดูประวัติเก่า
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="m-2">
            <vue-good-table
            :columns="tableColumns"
            :rows="Data"
            ref="refListTable"
           
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
              :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
           
            
            >
            <template
            slot="table-row"
            slot-scope="props"
        >       
                <span
                v-if="props.column.field === 'course_title'"
                class="text-nowrap"
                
                >   
                    <span class="text-nowrap" v-if="props.row.course_title">{{ props.row.course_title }} </span>
                    <span class="text-nowrap" v-else-if="props.row.meterial_title">{{ props.row.meterial_title }} </span>
                    <span class="text-nowrap" v-else-if="props.row.medicine_title">{{ props.row.medicine_title }} </span>
                    <span class="text-nowrap" v-else-if="props.row.checklist_title">{{ props.row.checklist_title }} </span>
                
                </span>
                <span
                v-else-if="props.column.field === 'used'"
                class="text-nowrap"
                
                >   
                    <span class="text-nowrap" v-if="props.row.used && !props.row.course_title && !props.row.meterial_title">{{ props.row.used }} </span>
                    <span class="text-nowrap" v-else>1</span>
                </span>
                <span
                v-else-if="props.column.field === 'courses_commission'"
                class="text-nowrap"
                
                >   
                    <span class="text-nowrap" v-if="props.row.courses_commission">{{ props.row.courses_commission }} </span>
                    <span class="text-nowrap" v-else-if="props.row.meterial_commission">{{ props.row.meterial_commission }} </span>
                    <span class="text-nowrap" v-else-if="props.row.checklist_price">{{ props.row.checklist_price }} </span>
                    <span class="text-nowrap" v-else>-</span>
                    
                  
                </span>
                <span
                v-else-if="props.column.field === 'courses_wage'"
                class="text-nowrap"
                
                >   
                    <span class="text-nowrap" v-if="props.row.courses_wage">{{ props.row.courses_wage }} </span>
                    <span class="text-nowrap" v-else-if="props.row.meterial_wage">{{ props.row.meterial_wage }} </span>
                    <span class="text-nowrap" v-else-if="props.row.checklist_wage">{{ props.row.checklist_wage }} </span>
                    <span class="text-nowrap" v-else>-</span>
                    
                </span>
                <span
                v-else-if="props.column.field === 'action'"
                class="text-nowrap"
                >
                <span v-if="props.row.course_title">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle" 
                    @click="updateCourse(props.row)"
                >
                    <feather-icon icon="Edit3Icon" />
                </b-button>
                </span>
                <span v-else-if="props.row.meterial_title">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle" 
                    @click="updateMeterial(props.row)"
                >
                    <feather-icon icon="Edit3Icon" />
                </b-button>
                </span>
                <span v-else-if="props.row.medicine_title">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle" 
                    @click="updateMedicine(props.row)"
                >
                    <feather-icon icon="Edit3Icon" />
                </b-button>
                </span>

                <span v-if="props.row.course_title">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    v-on:click="deleteconfirm(props.row.id,patient_id,props.row.course_id,'',props.row.course_title)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>
                </span>
                <span v-else-if="props.row.meterial_title">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    v-on:click="deleteconfirm(props.row.id,patient_id,'',props.row.meterial_id,props.row.meterial_title)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>
                </span>
                <span v-else-if="props.row.medicine_title">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    v-on:click="deleteconfirm(props.row.id,patient_id,'','',props.row.medicine_title)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>
                </span>
                <span v-else-if="props.row.checklist_title">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    v-on:click="deleteconfirm(props.row.id,patient_id,'','',props.row.checklist_title)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>
                </span>
                

                </span>
            </template>

            <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
             Items per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
           
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="Total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
             
              class="mt-1 mb-0"
            
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
            </vue-good-table>
        </div>
        <b-row align-h="start" class="pt-2">
            <b-col md="12">
                <div class="text-left">

                  <b-button
                    variant="transpant"
                    class="mr-2 border-primary text-dark"
                    type="button"
                    @click="addCheckList"
                    >
                    เพิ่มรายการตรวจ
                    </b-button>
                    <!--<b-button
                    variant="transpant"
                    class="mr-2 border-primary text-dark"
                    type="button"
                    @click="addCourse"
                    >
                    ใช้คอร์ด
                    </b-button>-->
                    
                    <b-button
                    variant="transpant"
                    class="mr-2 border-primary text-dark"
                    type="button"
                    @click="addMeterial"
                    >
                    ใช้วัสดุ/บริการ
                    </b-button>
                    <b-button
                    variant="transpant"
                    class="mr-2 border-primary text-dark"
                    type="button"
                    @click="addMedicine"
                    >
                    เพิ่มยา/อุปกรณ์
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </b-card>
    <b-row class="mb-2">
      <b-col>
        <b-form-textarea
        id="textarea"
        size="sm"
        placeholder="ลายละเอียดเพิ่มเติม"
        rows="5"
        no-resize
        v-model="opdcard_data.description"
        class="border-secondary text-dark"
        >
        </b-form-textarea>
      </b-col>
    </b-row>
    <b-row>      
      <b-col>
        <b-button
        variant="transpant"
        class="mr-2 border-primary text-dark"
        type="button"
        @click="toQueue()"
        >
        ห้องใช้บริการ
        </b-button>

        <b-button
        variant="transpant"
        class="mr-2 border-success text-dark"
        type="button"
        @click="updateQueueData(queue_id,2,4,opdcard_data.description)"
        >
        บันทึก
        </b-button>

      </b-col>
    </b-row>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker,BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import useRoomDetail from './useRoomDetail'
import roomDetailStoreModule from './roomDetailStoreModule'
import useItemDetail from './useItemDetail'
import itemStoreModule from './itemStoreModule'
import OpdItemForm from './OpdItemForm'
import { ref,onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import router from '@/router'
import CheckLists from './CheckLists.vue'
export default {
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker,BFormTextarea,
        VueGoodTable,
        vSelect,
        OpdItemForm,
        CheckLists

    },
    directives:{
        'b-modal': VBModal,
        Ripple

    },
    props: {
      patient_id:{
        type:Number,
        required: true,
      },
      opdcard_id:{
        required: true,
      },
    },
    setup(props, { emit }) {
        const queue_id = ref(router.currentRoute.params.queue_id);
        const isItemFormSidebarActive = ref(false);
        const isChecksFormSidebarActive = ref(false);
        const headertext = ref('');
        const ItemData = ref({});
        const Options = ref(0);
        const description = ref('');

        const userStorage = JSON.parse(localStorage.getItem('userData'));


        const PATIENTS_STORE_MODULE_NAME = 'app-roomdetail';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, roomDetailStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });


        const {
            tableColumns,
            Data,
            Total,
            fetchOpdcardItemData,
            fetchOpdcardItemDeleteData,
            updateQueueData,
            refListTable,
            pageLength,
            OpdItemData,
            OpdItemTotal,
            searchQuery,
            currentPage,
            opdcard_data,
            queueData,
            AddQueueService
        } = useRoomDetail(queue_id.value,props.opdcard_id);


        const addCourse = (data)=>{

        ItemData.value = {};
        headertext.value = 'ใช้คอร์ส';
        isItemFormSidebarActive.value = true;
        Options.value = 1;
        }
        const addMedicine = (data)=>{

        ItemData.value = {};
        headertext.value = 'เพิ่มยา/อุปกรณ์';
        isItemFormSidebarActive.value = true;
        Options.value = 2;
        }
        const addMeterial = (data)=>{
        ItemData.value = {};
        headertext.value = 'ใช้วัสดุ/บริการ';
        isItemFormSidebarActive.value = true;
        Options.value = 3;
        }
        
        const updateCourse = (data)=>{
        ItemData.value = data;
        headertext.value = 'แก้ไขคอร์ส';
        isItemFormSidebarActive.value = true;
        Options.value = 1;
        }
        const updateMedicine = (data)=>{
        ItemData.value = data;
        headertext.value = 'แก้ไขยา/อุปกรณ์';
        isItemFormSidebarActive.value = true;
        Options.value = 2;
        }
        const updateMeterial = (data)=>{
        ItemData.value = data;
        headertext.value = 'แก้ไขวัสดุ/บริการ';
        isItemFormSidebarActive.value = true;
        Options.value = 3;
        }
        const addCheckList =()=>{
          isChecksFormSidebarActive.value = true

        }

        return {
            addCourse,
            addMedicine,
            addMeterial,
            updateCourse,
            updateMedicine,
            updateMeterial,
            updateQueueData,
            tableColumns,
            Data,
            Total,
            fetchOpdcardItemData,
            fetchOpdcardItemDeleteData,
            refListTable,
            pageLength,
            OpdItemData,
            OpdItemTotal,
            searchQuery,
            currentPage,
            isItemFormSidebarActive,
            isChecksFormSidebarActive,
            ItemData,
            headertext,
            Options,
            queue_id,
            addCheckList,
            opdcard_data,
            queueData,
            userStorage,
            AddQueueService
        }
        
    },methods:{
        async toQueue(){

        let inpt = {
            opdcard_id:this.queueData.opdcard_id,
            patients_id:this.queueData.patient_id,
            doctor_id:this.queueData.doctor_id,
            room_id:this.queueData.room_id,
            status:1,
            branch_id:this.userStorage.branch_id,
            created_by:this.userStorage.id,
            date: new Date().toISOString().slice(0,10)

        }
        const inputData = ref(JSON.parse(JSON.stringify(inpt)));
        
        
        this.AddQueueService(inputData.value);
        this.updateQueueData(this.queue_id,2,2)
          

        
        //this.$router.push({name:'queue-service',query:{doctor:this.queueData.doctor_id}});
          //this.queueData.doctor_full_name
        },
        handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
        },
        deleteconfirm(id,patient_id,course_id,meterial_id,title){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete item: '+title, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
           this.fetchOpdcardItemDeleteData(id,patient_id,course_id,meterial_id)
          }
        })

        },
    },
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>