<template>
  <transition name="fade">
    <div>
    <template>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
        <b-form
            class="form-opd-primary"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >
        <b-card no-body class="p-2">
          <h4>เพิ่มคิวใช้บริการ</h4>
          <validation-provider
            #default="validationContext"
            name="doctor"
            rules="required"
          >
            <b-form-group
              label="เลือกแพทย์"
              label-for="doctor"
            >
              <v-select
                :options="Doctor_Data"
                v-model="Data.doctor_id"
                :get-option-label="(option) => option.first_name +' '+ option.last_name"
                :reduce="val => val.id"
                >
                <template #option="{ first_name,last_name }">
                {{ first_name }} {{ last_name }}
                <br />
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="room"
          >
            <b-form-group
              label="เลือกห้อง"
              label-for="room"
              rules="required"
            >
              <v-select
                :options="Room_Data"
                v-model="Data.room_id"
                :get-option-label="(option) => option.title"
                :reduce="val => val.id"
                >
                <template #option="{ title }">
                {{ title }}
                <br />
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="date"
            rules="required"
          >
            <b-form-group
              label="วันที่"
              label-for="date"

            >
              <b-form-datepicker
                id="date"
                v-model="Data.date"
                type="date"
                :state="getValidationState(validationContext)"
                trim
                placeholder="วันที่"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-card>
        </b-form>
        
        </validation-observer>
    </template>
  </div>
</transition>
</template>
<script>
 import router from '@/router'
import {
BSidebar, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref,onUnmounted } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import useQueueList from './useQueueList'
  import queueStoreModule from '../queueStoreModule'
  import store from '@/store'
  import { useToast } from 'vue-toastification/composition'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { slugify } from '@/@fake-db/utils'
export default {
    
    components: {
        BSidebar, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
        ValidationProvider, ValidationObserver,vSelect,BFormDatepicker
    },
    directives:{
        Ripple
    },
    props: {
      isQueueFormSidebarActive: {
        type: Boolean,
        required: true,
      },
     
      main_opdcard_id:{
        type: Number,
        required: true,
      }
    },
    setup(props, { emit }){

      const userStorage = JSON.parse(localStorage.getItem('userData'));
      const id = ref(router.currentRoute.params.id);
      const toast = useToast()
        const blankData = {

            opdcard_id:props.main_opdcard_id,
            doctor_id: '',
            room_id:'',
            status:1,
            check_status:0,
            branch_id:userStorage.branch_id,
            date:new Date().toISOString().slice(0,10),
            time:new Date().toLocaleTimeString().slice(0,8),
            patient_id:id.value,
            created_by:userStorage.id,
        
        }
        const Data = ref(JSON.parse(JSON.stringify(blankData)));
        const resetData = () => {
            Data.value = JSON.parse(JSON.stringify(blankData))
        }

        const PATIENTS_STORE_MODULE_NAME = 'app-queue';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, queueStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            Doctor_Data,
            Room_Data,
        } = useQueueList();

        const hide = ()=>{
        emit('update:is-queue-form-sidebar-active',false)
        emit('update:is-opd-detail-sidebar-active', true)
        emit('refetch-data')
        }

        const onSubmit = () => {
          Data.value.opdcard_id = props.main_opdcard_id;
          if(Data.value.id){
          store.dispatch('app-queue/updateData', Data.value)
          .then(() => {
            router.push({ name: 'queue-read', replace: true });
            toast({
            component: ToastificationContent,
            props: {
              title: "Save Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })

        }else{
          store.dispatch('app-queue/addData', Data.value)
          .then(() => {
            router.push({ name: 'queue-read', replace: true });
            toast({
            component: ToastificationContent,
            props: {
              title: "Save Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })

        }
        }
        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetData)
        return {
            Data,
            refFormObserver,
            getValidationState,
            resetForm,
            onSubmit,
            Doctor_Data,
            Room_Data,
            hide,

        }

    }
}
</script>