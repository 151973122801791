import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'


export default function useRoomList(queue_id,opdcard_id) {


  const searchQuery = ref('');
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const OpdItemData = ref({});
  const OpdItemTotal = ref(0);
  const dir = ref(false);
  const toast = useToast()
  const loading_data = ref(true);
  const patient_id = ref(0);
  const room_name = ref('');
  const opdcard_code = ref('');
  const Image_Data = ref([]);
  const Image_realte_Data = ref([]);
  const tableColumns = [

      { field: 'id', label: 'id', sortable: false, hidden:true },
        { field: 'course_title', label: 'รายการ', sortable: false },
        { field: 'used', label: 'จำนวนที่ใช้', sortable: false },
        { field: 'courses_commission', label: 'ค่าบริการ', sortable: false },
        { field: 'courses_wage', label: 'ค่าแพทย์', sortable: false },

        { field: 'action', label: 'การจัดการ', sortable: false },


    ]
    const opdcard_data = ref({});

    const queueData = ref({});
    const opdData = ref({});
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    const created_by = userStorage.id; 

    
    const Data = ref({});
    const Total = ref(0);

    watch([currentPage, pageLength, searchQuery], () => {
      fetchOpdcardItemData();
    })
    const fetchOpdcardData = async () =>{
      opdcard_data.value = {}
      await store
      .dispatch('app-roomdetail/fetchOpdcardData',{
        id:opdcard_id,
        branch_id:branch_id
      }).then(response => {
        opdcard_data.value = response.data.data[0];
        console.log('opdcard_data',opdcard_data.value);
        
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching opdcard description'",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    fetchOpdcardData();
   

    const fetchOpdcardItemData = async () =>{
      const offset = pageLength.value*(currentPage.value-1);

      await store
            .dispatch('app-roomdetail/fetchOpdcardItemData', {
              opdcard_id:opdcard_id,
              limit: pageLength.value,
              offset:offset,
              keywords: searchQuery.value,
              branch_id:branch_id,
              order_by:'opdcard_item.id DESC'
          
            })
            .then(response => {
              Data.value = response.data.data;
              for(let i=0;i<Data.value.length;i++){
                Data.value[i].medicine_id = JSON.parse(Data.value[i].medicine_id)
                Data.value[i].check_balance = JSON.parse(Data.value[i].check_balance)
                Data.value[i].main_sub_unit = JSON.parse(Data.value[i].main_sub_unit)
                Data.value[i].opdcard_item_id = JSON.parse(Data.value[i].opdcard_item_id)
              }

              const total = response.data.total?response.data.total: Data.value.length;
              Total.value = total;
            })
            .catch((error) => {
              console.log('error',error);
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error fetching patients group list",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
    }

    fetchOpdcardItemData();

    
    
    const fetchQueueData = async () =>{
      await store
          .dispatch('app-roomdetail/fetchQueueData', {
            id:queue_id,
            branch_id:branch_id
          })
          .then(response => {
            queueData.value = response.data.data[0];
            patient_id.value = queueData.value.patient_id
            room_name.value = queueData.value.room_name
            opdcard_code.value = queueData.value.code
          })
          .catch((error) => {
            console.log('error',error);
            router.push({ name: 'queue-read', replace: true })
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching queue'",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })

    }

    const updateQueueData = async (id,status,check_status,description) =>{
      if(description){
        /*const opdcard_Data = ref({
          id:opdcard_id,
          description:description
        });*/

      //  opdcard_Data.id = id;
       // opdcard_Data.description = description
       let dataque= {
        id:opdcard_id,
        description:description
       }

        await store
          .dispatch('app-roomdetail/updateDescriptionOpdcardData', dataque)
          .then(response => {

          })
          .catch((error) => {
             toast({
              component: ToastificationContent,
              props: {
                title: "Error queue save'",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            return error
          })

      }
      var data = ref({id:id,status:status,check_status:check_status})
      console.log('queue update',data)
      await store
          .dispatch('app-roomdetail/updateQueueData', data.value)
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Save Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
              })
              router.push({ name: 'queue-read', replace: true })
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error description save'",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })

    }

    const fetchOpdData = async () =>{
      loading_data.value = true;
      await store
          .dispatch('app-roomdetail/fetchOpdData', {
            branch_id:branch_id
          })
          .then(response => {
            loading_data.value = false;
            opdData.value = response.data.data[0];
          })
          .catch((error) => {
            console.log('error',error);
            router.push({ name: 'queue-read', replace: true })
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching opd'",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })

    }

    const updateOpdData = async () =>{
      await store
          .dispatch('app-roomdetail/fetchOpdData', {
            id:53,
            branch_id:branch_id
          })
          .then(response => {
            opdData.value = response.data.data[0];
          })
          .catch((error) => {
            console.log('error',error);
            router.push({ name: 'queue-read', replace: true })
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching opd'",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })

    }
    fetchOpdData();
    fetchQueueData();



  const fetchOpdcardItemDeleteData = async (id,patient_id,course_id,meterial_id) => {
    //fetchDeleteProperty
    if(course_id){
        var data = {id:0,patient_id:patient_id,course_id:course_id,plus_balance:1,created_by:created_by}
        await store.dispatch('app-roomdetail/updateCourseItemData',data)
    }
    var data = {id:id,patient_id:patient_id,created_by:created_by}
     await store.dispatch('app-roomdetail/fetchOpdcardItemDeleteData',data)
     await store.dispatch('app-roomdetail/deleteMedicineItemData',data)
     
     
    .then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchOpdcardItemData();
    }).catch((error)=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
  }
  
  const fetchOpdcardImageData = async () =>{
    await store
        .dispatch('app-roomdetail/fetchOpdcardImageData', {
          opdcard_id:parseInt(opdcard_id),
          branch_id:branch_id
        })
        .then(response => {
          Image_Data.value = response.data.data;
          console.log('Image_Data',Image_Data)
        })
        .catch((error) => {
          console.log('error',error);
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching opd_card Image'",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

  }
  fetchOpdcardImageData();

  const fetchOpdcardRelateImageData = async () =>{
    await store
        .dispatch('app-roomdetail/fetchOpdcardRelateImageData', {
          opdcard_id:parseInt(opdcard_id),
          branch_id:branch_id
        })
        .then(response => {
          Image_realte_Data.value = response.data.data;
        })
        .catch((error) => {
          console.log('error',error);
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching opd_card Image'",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

  }
  fetchOpdcardRelateImageData();
  const deleteOpdcardImageData = async (id) => {
    //fetchDeleteProperty
    var data = {id:id}
    
     await store.dispatch('app-roomdetail/deleteOpdcardImageData',data)
    .then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchOpdcardImageData();
    }).catch((error)=>{
      toast({
        component: ToastificationContent,
        props: {
          title: error,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
  }

  const AddQueueService = async (inputData)=>{
      

    const result = await store.dispatch('app-roomdetail/addQueueServiceData',inputData);
    if(result.data.success == true){
      let id = result.data.data.insertId;
      router.push({ name: 'queue-service-add',params:{id:id}, replace: true }).then(()=>{

        router.push({ name: 'queue-service', replace: true })

        toast({
            component: ToastificationContent,
            props: {
              title: "Create Service Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
    });
    }else{
      toast({
        component: ToastificationContent,
        props: {
          title: "Create Serice  Error",
          icon: 'AlertTriangleIcon',
          variant: 'error',
        },
      })

    }
   
  }

  const resolveUserStatusVariant = status => {
       
    if (status) return 'success'
    if (!status) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusnameVariant = status => {
  
      if (status) return 'Active'
      if (!status) return 'Inactive'
      return 'primary'
  }


    return {
        opdcard_data,
        fetchQueueData,
        tableColumns,
        fetchOpdcardItemData,
        fetchOpdcardItemDeleteData,
        Data,
        Total,
        OpdItemTotal,
        OpdItemData,
        pageLength,
        dir,
        searchQuery,
        currentPage,
        refListTable,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
        fetchOpdData,
        opdData,
        queueData,
        patient_id,
        room_name,
        opdcard_code,
        Image_Data,
        Image_realte_Data,
        fetchOpdcardImageData,
        deleteOpdcardImageData,
        updateQueueData,
        fetchOpdcardData,
        loading_data,
        AddQueueService

    }
}