import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/patients/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        async addData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/patients/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put(`/patients/v1/update`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .delete(`/patients/v1/delete/${data.id}/${data.point_id}`)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        addPointData(ctx,queryParams){
            
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                console.log('POST add point api')
                console.log(data)
                console.log('POST add point api')
              axios
                .post('/patients/points/v1/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchPatientVoucherData(ctx,queryParams){
          //var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .get('/patients/vouchers/v1/byPatients/'+queryParams.patient_id)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })

        },
        fetchTagData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/patients/tags/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        async addTagData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/patients/tags/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateTagData(ctx,queryParams){
            var data = queryParams;
            console.log(data)
            return new Promise((resolve, reject) => {
                axios
                  .put(`/patients/tags/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteTagData(ctx,queryParams){
 
            return new Promise((resolve, reject) => {
                axios
                  .delete('/patients/tags/v1/delete/'+queryParams.id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },

        //group
        fetchGroupData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/patients/groups/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        fetchServicesData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/queue/service/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        async addGroupData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/patients/groups/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateGroupData(ctx,queryParams){
            var data = queryParams;
            console.log(data)
            return new Promise((resolve, reject) => {
                axios
                  .put(`/patients/groups/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteGroupData(ctx,queryParams){
 
            return new Promise((resolve, reject) => {
                axios
                  .delete('/patients/groups/v1/delete/'+queryParams.id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },

        //fetchPatientCourseData
        fetchPatientCourseData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .get('/receipt/v1/getcourses/'+queryParams.patient_id)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        //fetchPatientAppointmentData
        fetchPatientAppointmentData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/appointment/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        //fetchPatientMedicineData from all receipt
        fetchPatientMedicineData(ctx,queryParams){
              
            return new Promise((resolve, reject) => {
              axios
                .get('/receipt/v1/medicine/patients/'+queryParams.patient_id)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },

        //import csv
        async importCSVData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/patients/v1/import/csv',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        fetchBranchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/branch/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        addPatientSignImageData(ctx,queryParams){
          var data = queryParams;

          return new Promise((resolve, reject) => {
            axios
              .post('/patients/consent/v1/add',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })

      },
        updatePatientSignImageData(ctx,queryParams){
          var data = queryParams;
             return new Promise((resolve, reject) => {
               axios
                 .put('/patients/consent/v1/update/'+data.id,data)
                 .then(response => resolve(response))
                 .catch(error => reject(error))
             })
 
         },

    }
}