<template>
    <div>
    <b-card>
        <b-row>
            <b-col md="6" class="text-left">
                <b-breadcrumb
                :items="[{
                  text: 'คิวห้องตรวจ',
                  to: { name: 'queue-read' }
                },
                {
                  text: room_name,
                  active: true,
                },
                {
                  text: opdcard_code,
                  active: true,
                },]"
                />
                
            </b-col>
            <b-col md="6" class="text-right">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                >
                    <feather-icon icon="FileIcon" />
                    เพิ่มใบส่งตัวผู้ป่วย
                </b-button>
    
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="ml-1"
                >
                    <feather-icon icon="ClipboardIcon" />
                    เพิ่มใบรับรองการเจ็บป่วย
                </b-button>
    
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="ml-1"
                >
                    <feather-icon icon="FileTextIcon" />
                    เพิ่มใบรับรองแพทย์
                </b-button>
            </b-col>
        </b-row>
    </b-card>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-tabs v-model="tabIndex" pills card
                    class="tab-list-opd"
                    >
                        <b-tab 
                        title="การตรวจข้อมูลเบื่องต้น"
                        :title-link-class="linkClass(0)"
                        :lazy=true
                        @click="fetchOpdcardData"
    
                        >
                        <template #title >
                            <feather-icon icon="FileIcon"/>การตรวจข้อมูลเบื่องต้น
                        </template>
                        <b-row>
                            <b-col cols="6">
                                <h4
                                class="text-dark text-left"
                                >
                                    {{opdcard_code}}
                                </h4>
                            </b-col>
                            <b-col cols="6">
                                <h4
                                class="text-dark text-right"
                                >
                                    {{room_name}}
                                </h4>
                            </b-col>
                        </b-row>
                        <b-row class="pt-2">
                            <!--<b-col
                            cols="4"
                            class="pr-2"
                            >
                            <b-card
                            class="mb-2 text-dark border-primary"
                            img-src="https://media.istockphoto.com/id/1341490004/photo/doctors-and-nurses-celebrating-senior-man-leaving-the-hospital-after-recovery-wearing.jpg?s=612x612&w=0&k=20&c=v2YLIuogzQgOOa3Q_5TWQ6rZp6WGfKH1TLtP-FnEei0="
                            img-alt="Image"
                            img-top
                            style="max-width: 30rem;"
                            >
                            <small>รูปล่าสุด</small>
    
    
                            <b-form-textarea
                            id="textarea"
                            size="sm"
                            placeholder="ลายละเอียดเพิ่มเติม"
                            rows="5"
                            >
                            </b-form-textarea>
                            </b-card>
                            </b-col>
                            -->
                            <b-col
                            cols="12"
                            >
                                <b-card
                                class="border-primary"
                                v-if="!loading_data"
                                >
                                <opd-form 
                                v-if="patient_id && opdcard_data.id"
                                :is-opd-form-sidebar-active.sync="isOpdFormSidebarActive"
                                :patient_id.sync="patient_id"
                                :opdData.sync="opdcard_data"
                                ></opd-form>
                                </b-card>
                            </b-col>
                        </b-row>
                        </b-tab>
                        <b-tab
                        title="รายการตรวจ"
                        :title-link-class="linkClass(1)"
                        :active="!isOpdFormSidebarActive"
                        >
                        <template #title >
                            <feather-icon icon="ListIcon"/>รายการตรวจ
                        </template>
                        <b-row>
                            <b-col cols="6">
                                <h4
                                class="text-dark text-left"
                                >
                                    {{opdcard_code}}
                                </h4>
                            </b-col>
                            <b-col cols="6">
                                <h4
                                class="text-dark text-right"
                                >
                                    {{room_name}}
                                </h4>
                            </b-col>
                        </b-row>
                        <b-row class="pt-2">
                            <b-col
                            cols="12"
                            >
                                <b-card
                                class="border-primary"
                                >
                                <opd-check-list 
                                v-if="patient_id && opdcard_id"
                                :patient_id.sync="patient_id"
                                :opdcard_id.sync="opdcard_id"
                                ></opd-check-list>
                                </b-card>
                            </b-col>
                        </b-row>
                        </b-tab>
                        <b-tab
                        title="รูปภาพ (before)"
                        :title-link-class="linkClass(2)"
                        >
                        <template #title >
                            <feather-icon icon="CameraIcon"/>รูปภาพเกี่ยวข้อง
                        </template>
                        <b-row class="pt-2">
                            <b-col
                            cols="12"
                            >
                                <b-card
                                class="border-primary"
                                >
                                <opd-image-list 
                                v-if="opdcard_id"
                                :opdcard_id.sync="opdcard_id"
                                ></opd-image-list>
                                </b-card>
                            </b-col>
                        </b-row>
                        </b-tab>
                        <b-tab
                        title="รูปภาพ (after)"
                        :title-link-class="linkClass(3)"
                        >
                        <template #title >
                            <feather-icon icon="CameraIcon"/>อัพโหลดรูปภาพ
                        </template>
                        <b-row class="pt-2">
                            <b-col
                            cols="12"
                            >
                                <b-card
                                class="border-primary"
                                >
                                <opd-image-form 
                                v-if="opdcard_id"
                                :opdcard_id.sync="opdcard_id"
                                ></opd-image-form>
                                </b-card>
                            </b-col>
                        </b-row>
                        </b-tab>
                        <b-tab
                        title="ใบหน้าคนไข้"
                        :title-link-class="linkClass(4)"
                        :active="!isFaceFormSidebarActive"
                        @refetch-data="fetchOpdcardData"
                        >
                        <template #title >
                            <feather-icon icon="UserIcon"/>ใบหน้าคนไข้
                        </template>
                        <face-form 
                        v-if="patient_id && opdcard_data.id"
                        :is-face-form-sidebar-active.sync="isFaceFormSidebarActive"
                        :patient_id.sync="patient_id"
                        :opdData.async="opdcard_data"
                        />
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
    </div>
    </template>
    <script>
    import {
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BTab,BTabs,BBreadcrumb,BFormTextarea
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
    import { VueGoodTable } from 'vue-good-table'
    import store from '@/store'
    import OpdForm from '../../opd/opd-form/OpdForm.vue'
    import OpdImageForm from './OpdImageForm.vue'
    import OpdImageList from './OpdImageList.vue'
    import OpdCheckList from './OpdCheckList.vue'
    import FaceForm from './FaceForm.vue'
    import useRoomDetail from './useRoomDetail'
    import roomDetailStoreModule from './roomDetailStoreModule.js'
    import { ref,onUnmounted } from '@vue/composition-api'
    
    
    import vSelect from 'vue-select'
    import { useToast } from 'vue-toastification/composition'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import router from '@/router'
    export default {
        components:{
            BBreadcrumb,
            BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
            BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
            FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,BTab,BTabs,BFormTextarea,
            VueGoodTable,
            vSelect,
            OpdForm,
            OpdImageForm,
            OpdImageList,
            OpdCheckList,
            FaceForm,
    
        },
        directives:{
            'b-toggle': VBToggle,
            'b-modal': VBModal,
            Ripple
    
        },
        created(){
           //this.fetchOpdcardData();
        },
        setup(props, { emit }) {
            const queue_id = ref(router.currentRoute.params.queue_id);
            const opdcard_id = ref(router.currentRoute.query.opdcard_id);
            console.log('opdcard_id',opdcard_id)
            const tabIndex = ref(0);
            const isOpdFormSidebarActive = ref(true);
            const isFaceFormSidebarActive = ref(true);
            const PATIENTS_STORE_MODULE_NAME = 'app-roomdetail';
            if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, roomDetailStoreModule)
            onUnmounted(() => {
                if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
            });
            const {
                patient_id,
                room_name,
                opdcard_code,
                fetchOpdData,
                fetchOpdcardItemData,
                opdcard_data,
                fetchOpdcardData,
                loading_data
            } = useRoomDetail(queue_id.value,opdcard_id.value);
            loading_data.value = true;
           
            return { 
                isOpdFormSidebarActive,
                isFaceFormSidebarActive,
                tabIndex,
                patient_id,
                room_name,
                opdcard_code,
                fetchOpdData,
                fetchOpdcardItemData,
                opdcard_id,
                opdcard_data,
                fetchOpdcardData,
                loading_data

            }
    
            
        },
        methods: {
          linkClass(idx) {
            if (this.tabIndex === idx) {
              return [ 'text-light']
            } else {
              return ['border-primary', 'text-dark']
            }
          }
        },
    
    }
    </script>
    <style lang="scss">
    @import '@core/scss/vue/libs/vue-good-table.scss';
    .tab-list-opd{
        li{
            margin-right:10px;
            background:#F5F8FF;
        }
    }
    </style>