import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/queue/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        async addReceipt(ctx,queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                  .post('/receipt/v1/add-first',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async addData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
            console.log('addData',data);

            return new Promise((resolve, reject) => {
                axios
                  .post('/queue/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put(`/queue/v1/update`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .delete(`/queue/v1/delete/${data.id}`)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async cancelData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .delete(`/queue/v1/cancel/${data.id}`)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        fetchPatientData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/patients/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchDoctorData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/users/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchRoomData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/rooms/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
    }
}