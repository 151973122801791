<template>
    <div>
        <b-card no-body
        >
            <div class="m-2">

                <b-row v-if="Image_realte_Data.image">
                    <b-col class="mr-2 mb-2" lg="2" v-for="i,index in Image_realte_Data.image.length">
                        <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                        >
                        <b-media-aside>
                          <div class="d-inline-block">
                            <div v-if="Image_realte_Data.image[index]">
                            <b-img
                                ref="refPreviewEl"
                                :src="Image_realte_Data.image[index]"
                                height="200"
                                width="200"
                                class="mr-2 mb-1 mb-md-0"
                            />
                            
                            </div>
                          </div>
                        </b-media-aside>
                      </b-media>
                    </b-col> 
                </b-row>
               
            </div>
           
        </b-card>
    </div>
    </template>
    <script>
    import { BBreadcrumb } from 'bootstrap-vue'
    import router from '@/router'
     import {
       BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab, BFormSelect
     } from 'bootstrap-vue'
     //upload
     import {
      BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol
     } from 'bootstrap-vue'
    
    //validation
     import { ValidationProvider, ValidationObserver } from 'vee-validate'
     import { ref,onUnmounted } from '@vue/composition-api'
     import { required, alphaNum, email, password,confirmed } from '@validations'
     import formValidation from '@core/comp-functions/forms/form-validation'
     import Ripple from 'vue-ripple-directive'
     import store from '@/store'
 
     import useRoomDetail from './useRoomDetail.js'
     import roomDetailStoreModule from './roomDetailStoreModule.js'

     //file
     import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
     import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import { useToast } from 'vue-toastification/composition'
    export default {
        components:{
            BBreadcrumb,
            BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab,
            BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
        },
        props: {
            opdcard_id:{
            required: true,
            },
        },
        setup(props, { emit }){
            const queue_id = ref(router.currentRoute.params.queue_id);
            const userStorage = JSON.parse(localStorage.getItem('userData'));
            const toast = useToast()
            const PATIENTS_STORE_MODULE_NAME = 'app-roomdetail';
            if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, roomDetailStoreModule)
            onUnmounted(() => {
                if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
            });
            const {

                Image_realte_Data,

            } = useRoomDetail(0,props.opdcard_id);


            return {

                Image_realte_Data,
            }
    
        }
        
    }
    </script>