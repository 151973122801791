var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('div',[[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"form-opd-primary",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-card',{staticClass:"p-2",attrs:{"no-body":""}},[_c('h4',[_vm._v("เพิ่มคิวใช้บริการ")]),_c('validation-provider',{attrs:{"name":"doctor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"เลือกแพทย์","label-for":"doctor"}},[_c('v-select',{attrs:{"options":_vm.Doctor_Data,"get-option-label":function (option) { return option.first_name +' '+ option.last_name; },"reduce":function (val) { return val.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var first_name = ref.first_name;
var last_name = ref.last_name;
return [_vm._v(" "+_vm._s(first_name)+" "+_vm._s(last_name)+" "),_c('br')]}}],null,true),model:{value:(_vm.Data.doctor_id),callback:function ($$v) {_vm.$set(_vm.Data, "doctor_id", $$v)},expression:"Data.doctor_id"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"room"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"เลือกห้อง","label-for":"room","rules":"required"}},[_c('v-select',{attrs:{"options":_vm.Room_Data,"get-option-label":function (option) { return option.title; },"reduce":function (val) { return val.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
return [_vm._v(" "+_vm._s(title)+" "),_c('br')]}}],null,true),model:{value:(_vm.Data.room_id),callback:function ($$v) {_vm.$set(_vm.Data, "room_id", $$v)},expression:"Data.room_id"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"วันที่","label-for":"date"}},[_c('b-form-datepicker',{attrs:{"id":"date","type":"date","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"วันที่"},model:{value:(_vm.Data.date),callback:function ($$v) {_vm.$set(_vm.Data, "date", $$v)},expression:"Data.date"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.hide}},[_vm._v(" Cancel ")])],1)],1)],1)]}}])})]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }