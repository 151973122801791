import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/checklists/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        async addData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/checklists/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        //add,update,delete checklist advice
        async addChecklistAdviceData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/checklists/advice/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateChecklistAdviceData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put(`/checklists/advice/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteChecklistAdviceData(ctx,queryParams){

            return new Promise((resolve, reject) => {
                axios
                  .delete(`/checklists/advice/v1/delete/`+queryParams.id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put(`/checklists/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteData(ctx,queryParams){
 
            return new Promise((resolve, reject) => {
                axios
                  .delete('/checklists/v1/delete/'+queryParams.id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async fetchCategoryData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/checklists/category/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        fetchAdviceData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/advice/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
    }
}