<template>
    <div class="my-1">
        <transition name="fade">
    <b-row>
        <b-col>
            <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                >
            <b-form
                class="form-opd-primary"
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
                >
            
            <b-card no-body class="p-2">
                <b-row>
                    <b-col cols="6">
                        <div>
                            <b-media
                                no-body
                                vertical-align="center"
                                class="flex-column flex-md-row"
                            >
                            <b-media-aside>
                                <b-img
                                    ref="refPreviewEl"
                                    v-if="Data.faceImage"
                                    :src="Data.faceImage"
                                    height="550"
                                    width="520"
                                    class="border"
                                />
                                <b-img
                                    ref="refPreviewEl"
                                    v-else
                                    height="550"
                                    width="520"
                                    class="border"
                                />
                            </b-media-aside>
                        </b-media> 
                        <div class="mt-2 my-1">
                        <span class="">อัพโหลดรูปภาพ</span>
                        <b-form-file
                            id="fileInput"
                            class="visuallyhidden"
                            ref="refInputEl"
                            size="sm"
                            v-model="Data.imagesFile"
                            accept=".jpg, .png, .gif"
                            
                            @input="inputImageRenderer"
                            @change="setBackgroundCanvas($event)"
                            />  
                        </div>
                        </div>
                    </b-col>
                    <b-col cols="6">
                <div>
                    <div class="border">
                        <vue-drawing-canvas ref="VueCanvasFaceDrawing" 
                        :line-cap="'round'"
                        :line-join="'round'"
                        width="520"
                        height="550"
                        :background-image="Data.backgroundImage"
                        
                        
                        />
                    </div>
                    <div class="mt-2 my-1">
                    <button class="btn btn-outline-secondary btn-sm" @click.prevent="$refs.VueCanvasFaceDrawing.undo()">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-counterclockwise"
                    viewBox="0 0 16 16"
                    >
                    <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                    />
                    <path
                        d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
                    />
                    </svg>
                    Undo
                    </button>
                    <button class="mx-1 btn btn-outline-secondary btn-sm" @click.prevent="$refs.VueCanvasFaceDrawing.redo()">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                    >
                    <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path
                        d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
                    />
                    </svg>
                    Redo
                    </button>

                    <button class="mr-2 btn btn-outline-secondary btn-sm" @click.prevent="$refs.VueCanvasFaceDrawing.reset()">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-earmark"
                    viewBox="0 0 16 16"
                    >
                    <path
                        d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"
                    />
                    </svg>
                    Reset
                    </button>
                </div>
                </div>
                </b-col>
                </b-row>
                <div class="d-inline-block m-2">
                <b-button
                :disabled="loading"
                variant="transpant"
                class="mr-2 border-primary text-primary float-right"
                type="submit"
                >
                บันทึก
                </b-button>
                </div>
            </b-card>
            </b-form>
            </validation-observer>
        </b-col>
    </b-row>
    </transition>
    </div>
</template>
<script>
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,
  BButton
 } from 'bootstrap-vue'
 import {
   BSidebar, BBadge, BForm, BFormGroup, BFormRadioGroup, BInputGroup, BFormInput, BFormRadio, BFormTextarea, BFormCheckbox, BFormInvalidFeedback
 } from 'bootstrap-vue'
 import { ref,onUnmounted,beforeMount  } from '@vue/composition-api'
 import Ripple from 'vue-ripple-directive'
 import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
 import router from '@/router'
 import store from '@/store'
 import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//validate
 import { ValidationProvider, ValidationObserver } from 'vee-validate'
 import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import opdStoreModule from '../opdStoreModule'
 import VueDrawingCanvas from "vue-drawing-canvas";
 import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
 export default {
    directives: {
        Ripple,
    },
    model: {
    prop: 'isFaceFormSidebarActive',
    event: 'update:is-face-form-sidebar-active',
    },
    props: {
    opdData:{
        type: Object,
    },
    patient_id:{
        type:Number,
    }
    },
    components:{
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,
        BButton,BInputGroup,BBadge,
        FeatherIcon,ValidationProvider, ValidationObserver,
        BSidebar, BForm, BFormGroup, BFormRadioGroup, BFormInput, BFormRadio, BFormTextarea, BFormCheckbox, BFormInvalidFeedback,
        BFormSelect,VueDrawingCanvas
    },
    setup(props, { emit }){
        const loading = ref(false);
        const userStorage = JSON.parse(localStorage.getItem('userData'));
        const id = ref(0);
        const opdcard_id = ref(0);
        const VueCanvasFaceDrawing =ref();
        if(props.patient_id !== undefined){
            id.value = props.patient_id; 
        }
        else{
            id.value = router.currentRoute.params.id;
        }
        const toast = useToast()
        const blankData = {
            id:opdcard_id.value,
            face_image:'',
            face_after_image:'',
            imagesFile:null,
        }
        const Data = ref(JSON.parse(JSON.stringify(blankData)))
        const resetData = () => {
        Data.value = JSON.parse(JSON.stringify(blankData))
        }
        

        if(props.opdData){ 
            
            Data.value  = JSON.parse(JSON.stringify(props.opdData))
            Data.value.faceImage = Data.value.face_image?Data.value.facefullpath:'';
           
            Data.value.backgroundImage = Data.value.face_image?Data.value.facefullpath:'';
            Data.value.backgroundImage = Data.value.face_after_image?Data.value.face_after_image.data:''; 
            Data.value.backgroundImage = Data.value.backgroundImage.map(b => String.fromCharCode(b)).join('')


        }

        const PATIENTS_STORE_MODULE_NAME = 'app-opd-face';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, opdStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        
        const onSubmit = () => {
            let formData = new FormData();
            loading.value = true
            Data.value.ref = 'viva';
            Data.value.path = '/var/www/html/uploadfiles/uploads/viva/patients/';
            Data.value.savepath = 'uploads/viva/patients/';
            Data.value.face_after_image = VueCanvasFaceDrawing.value.save();
            for(const [key, value] of Object.entries(Data.value)) {
              formData.append(key, value);
            }
            if(Data.value.id){
                loading.value = false
                store.dispatch('app-opd-face/updateOpdCardFaceImageData', {formData:formData,data:Data.value})
                .then((response) => {
                    emit('refetch-data')
                    emit('update:is-face-form-sidebar-active', false)
                    
                    toast({
                    component: ToastificationContent,
                    props: {
                    title: "Save Success",
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                    },
                })
                }).catch(()=>{
                    loading.value = false
                toast({
                    component: ToastificationContent,
                    props: {
                    title: "Error Save",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    },
                })
            
                })

        }
        }

        //uploadimage
        const refInputEl = ref(null);
            const refPreviewEl = ref(null);
            const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => { 
                    
                    refPreviewEl.value.src = base64
            })

        const {
        refFormObserver,
        getValidationState,
        resetForm,
        } = formValidation(resetData)
        return {
            refFormObserver,
            getValidationState,
            resetForm,
            onSubmit,
            Data,
            loading,
            inputImageRenderer,
            refInputEl,
            refPreviewEl,
            VueCanvasFaceDrawing,
        }

    },
    methods:{
        async setBackgroundCanvas(event){
            let URL = window.URL;
            this.Data.backgroundImage = URL.createObjectURL(event.target.files[0]);
            await this.$refs.VueCanvasFaceDrawing.redraw();
            await this.$refs.VueCanvasFaceDrawing.reset();
        },
    }

 }

</script>