<template>
   <b-sidebar
    id="drop-item-form-sidebar"
    :visible="isChecksFormSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-sm"
    shadow
    backdrop
    no-header
    right
    @shown="getData"

    @change="(val) => $emit('update:is-checks-form-sidebar-active', val)"
  >
    <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
                รายการตรวจ
            </h5>
            <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
            />

        </div>
        <div class="m-2">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                      label="หมวดหมู่"
                      label-for="category_id"
                      
                      >
                      <v-select
                      :options="categories"
                        v-model="category_id"
                        :get-option-label="(option) => option.title"
                        :reduce="val => val.id"
                        @input="getCheckLists"
                      ></v-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col md="4">
                รายการ
              </b-col>
              <b-col md="4">
                ราคา
              </b-col>
              <b-col md="4">
                จำนวนที่ใช้
              </b-col>
          </b-row>
      
          <div v-for="(item,index) in checklists" :key="'muti-'+index">
            <b-row class="mt-1">
                <b-col md="4">
                {{ item.title}}
              </b-col>
              <b-col md="4">
                {{  item.price }}
              </b-col>
              <b-col md="4">
                <b-button  variant="outline-info" @click="addCheckList(item)">+</b-button>
              </b-col>
            </b-row>
          </div>
          <hr />
          <div v-for="(item,index) in selecteds" :key="'selected-'+index">
            <b-row class="mt-1">
                <b-col md="4">
                {{ item.title}}
              </b-col>
              <b-col md="4">
                {{  item.price }}
              </b-col>
              <b-col md="4">
                <b-button  variant="outline-danger" @click="removeCheckList(index)">-</b-button>
              </b-col>
            </b-row>
          </div>

          <div class="d-flex mt-2">
            <b-button
              :disabled="selecteds.length == 0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="onSubmit"
            >
              Submit
            </b-button>
            <b-button
            
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

          

        </div>
    </template>
  </b-sidebar>

</template>
<script>
import {
  BDropdown, BDropdownItem, BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,BFormSelect,BFormSelectOption,BFormDatepicker,BFormTimepicker,
  BInputGroup,BInputGroupAppend,BInputGroupPrepend,BRow, BCol,BBadge,BFormSpinbutton,BButtonGroup,BFormRadioGroup,BFormRadio,BTooltip
} from 'bootstrap-vue'
import { ref,onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import categoryStoreModule from '@/views/checklists/categoryStoreModule'
import checklistsStoreModule from '@/views/checklists/checklistsStoreModule'
import itemStoreModule from './itemStoreModule'
export default {
    components:{
        BDropdown, BDropdownItem, BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,BFormSelect,BFormSelectOption,BFormDatepicker,BFormTimepicker,
        BInputGroup,BInputGroupAppend,BInputGroupPrepend,BRow, BCol,BBadge,BFormSpinbutton,BButtonGroup,BFormRadioGroup,BFormRadio,BTooltip,
        vSelect

    },
    directives: {
    Ripple,
  },
  model: {
    prop: 'isChecksFormSidebarActive',
    event: 'update:is-checks-form-sidebar-active',
  },
  props: {
    isChecksFormSidebarActive: {
      type: Boolean,
      required: true,
      
    },
    opdcard_id:{
      required: true,
    },
    patient_id:{
      type:Number,
      required: true,
    },
   

  },
  setup(props, { emit }) {
    const toast = useToast();
    const categories = ref([]);

    const category_id = ref();
    const currentPage = ref(1);
  const pageLength = ref(10);
  const checklists = ref([]);
  const Total = ref(0);
  const dir = ref(false);
  const selecteds = ref([]);
  const userStorage = JSON.parse(localStorage.getItem('userData'));
    const CATEGORY_STORE_MODULE_NAME = 'app-checklists-category';
        if (!store.hasModule(CATEGORY_STORE_MODULE_NAME)) store.registerModule(CATEGORY_STORE_MODULE_NAME, categoryStoreModule)
        onUnmounted(() => {
            if (store.hasModule(CATEGORY_STORE_MODULE_NAME)) store.unregisterModule(CATEGORY_STORE_MODULE_NAME)
        });
    const CHECKLIST_STORE_MODULE_NAME = 'app-checklists';
    if (!store.hasModule(CHECKLIST_STORE_MODULE_NAME)) store.registerModule(CHECKLIST_STORE_MODULE_NAME, checklistsStoreModule)
    onUnmounted(() => {
        if (store.hasModule(CHECKLIST_STORE_MODULE_NAME)) store.unregisterModule(CHECKLIST_STORE_MODULE_NAME)
    });
    const ITEMS_STORE_MODULE_NAME = 'app-item';
      if (!store.hasModule(ITEMS_STORE_MODULE_NAME)) store.registerModule(ITEMS_STORE_MODULE_NAME, itemStoreModule)
      onUnmounted(() => {
          if (store.hasModule(ITEMS_STORE_MODULE_NAME)) store.unregisterModule(ITEMS_STORE_MODULE_NAME)
      });
    const getData = async()=>{
        getCategoryies();
        getCheckLists();
    }
    const getCategoryies = async()=>{
       let cats = await store
                    .dispatch('app-checklists-category/fetchCategoryData', {
                    
                    group_id:1,
                    is_active:1,
                   // branch_id:branch_id,
                    order_by:'id DESC'
                
                    })
        if(cats.data.success){
            categories.value = cats.data.data
            
        }
        console.log('cats',categories.value);

    }
    const getCheckLists = async()=>{
        const offset = pageLength.value*(currentPage.value-1);
       let checks = await store
                    .dispatch('app-checklists/fetchData', {
                    
                        limit: pageLength.value,
                        offset:offset,
                        //keywords: searchQuery.value,
                       // group_id:selected_group.value,
                        category_id:category_id.value,
                      //  type_id:selected_type.value,
                        is_active:1,
                       // branch_id:branch_id,
                        order_by:'checklists.id DESC'
                
                    })
        console.log('checks',checks);
        if(checks.data.success){
            Total.value = checks.data.total;
            
            checklists.value = checks.data.data
            
        }
      

    }
    const addCheckList = async(item)=>{
        let itm = {
            course_id:null,
            meterial_id:null,
            checklist_id:item.id,
            branch_id:userStorage.branch_id,
            opdcard_id:props.opdcard_id,
            created_by:userStorage.id,
            title:item.title,
            price:item.price,
            patient_id:props.patient_id,
            qty:1

        }
        selecteds.value.push(itm);
        //course_id,meterial_id,checklist_id,branch_id,patient_id,opdcard_id,created_by
        //selected

    }
    const removeCheckList = async(index)=>{
        selecteds.value.splice(index, 1);

    }
    const onSubmit = async()=>{
         await selecteds.value.map(async (value)=>{
            await store.dispatch('app-item/addOpdcardItemData',value)

        });
        emit('update:is-checks-form-sidebar-active', false)
        emit('refetch-data')
        toast({
          component: ToastificationContent,
          props: {
            title: "Save Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
          })

    }
    return {
        getData,
        categories,
        category_id,getCheckLists,
        currentPage,
        Total,
        dir,
        checklists,
        addCheckList,
        selecteds,
        removeCheckList,
        onSubmit
    }

  }
    
}
</script>